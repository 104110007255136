<template>
  <div class="container">
    <div class="form-group form-row p-2">
      <h5 class="font-weight-bold">Save {{ this.chartTitle }} Chart</h5>
    </div>
    <div class="form-group form-row align-items-center">
      <label for="name" class="m-1 font-weight-bold">Chart Name</label>
      <input v-model="clientChart.name" id="name" name="name" type="text" class="form-control col-9 w-100" />
    </div>
    <div class="form-group form-inline align-items-center">
      <input type="checkbox" id="required" name="required" v-model="clientChart.useCurrentTimePeriod" true-value="Y" false-value="N" />
      <label for="required" class="control-label pl-1">Always use most recent time period</label>
     </div>    
    <div class="save-container form-group form-row p-2">
      <div class="form-inline col">
        <input type="radio" v-model="clientChart.clientChartId" name="save" :value="clientChart.clientChartId" />
        <label for="save" class="ml-1">Save</label>
      </div>
      <div class="form-inline col">
        <input type="radio" v-model="clientChart.clientChartId" name="save" value="0" />
        <label for="save" class="ml-1">Save As New</label>
      </div>
    </div>
    <div role="group" class="chart-buttons btn-group-horizontal">
      <button class="btn btn-primary" type="button" @click="saveClientChart">
        <span>{{ buttonLabel }}</span>
        <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
      </button>
      <button type="button" class="btn btn-primary ml-4" @click="closeSaveChartModal">Close</button>
    </div>
  </div>
</template>
<script>
import AdminService from "../../services/admin.service";
import ClientChartService from "../../services/client.chart.service";
import GoogleService from "../../services/google.service";

export default {
  name: "SaveChart",
  data() {
    return {
      clientChart: {}
    };
  },
  props: {
    chartTitle: {
      type: String,
      default: ""
    },
    modalName: {
      type: String,
      default: "save-chart-confirmation"
    },
    buttonLabel: {
      type: String,
      default: "Save as Card"
    },
    initialClientChart: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  methods: {
    closeSaveChartModal() {
      this.$emit('closeModal');
    },
    saveClientChartCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.$emit("save", response.data.data, response.data.message, this.modalName);
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async saveClientChart() {
      GoogleService.logSaveCardEventCriteria(this.chartTitle, this.clientChart.name, this);
      this.$root.processServerRequest();
      ClientChartService.saveClientChart(this.clientChart,this.saveClientChartCallback,this);
    }
  },
  umnounted() {},
  computed: {},
  created() {
    this.clientChart = this.initialClientChart;
  },
  watch: {}
};
</script>
