import ClientService from "./client.service";
import UserService from "./user.service";
import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import AdminService from "./admin.service";
import $ from "jquery";
const BASE_URL = AdminService.getBaseUrl();
const API_URL = "/api/v1/chart";
const DEFAULT_FONT_SIZE = 10;

class EChartsService {
    labelLayout(params) {
        return {
            x: params.rect.x + 5,
            y: params.rect.y + params.rect.height / 2,
            verticalAlign: 'middle',
            align: 'left'
        }
    }
    setOptions(chartOptions, yAxisData, seriesData,header){
        chartOptions = this.setSeriesData(chartOptions, seriesData);
        $.extend(true, chartOptions, this.getBaseOptionsForChart());
        $.extend(true, chartOptions.yAxis, this.getBaseYAxis());
        chartOptions.yAxis.data.push(yAxisData);

        let isWillingnessToPay = header.indexOf("Willingness to Pay") > -1;
        let data = this.parseSeriesDataForSankeyChart(chartOptions);
        chartOptions = this.getOptionsForSankeyChart(data,header,isWillingnessToPay);
        return chartOptions;
    }
    /* Sankey Parser Functions start */
    parseSeriesDataForSankeyChart(chartOptions){
        let returnSeriesData = [];
        let seriesData = chartOptions.series;
        for (let i = 0; i < seriesData.length; i++){
          let oldSeriesEntry = seriesData[i].data[0];
          let color = seriesData[i].color;
          let newSeriesEntry = {
            name: oldSeriesEntry.name,
            value: oldSeriesEntry.value,
            color: color,
          };
          returnSeriesData.push(newSeriesEntry);
        }
        return returnSeriesData;
    }
    getBaseLabelForSankeyChart(formatter,isHorizontal){
        let position = isHorizontal == true ? 'right' : 'bottom';
        let options = {
            position: position,
            color: "#000",
            fontSize: 10.5,
            fontFamily: "Franklin Gothic Demi Cond"                        ,
            formatter: formatter,
        };
        return options;
    }
    getDataEntry(dataEntry,isHorizontal){
        let name =  dataEntry.name + " " + dataEntry.value + "%";
        let formatter = dataEntry.name + "\n" + dataEntry.value + "%";
            return  {
            name: name,
            itemStyle: {
                color: dataEntry.color
            },
            label: this.getBaseLabelForSankeyChart(formatter,isHorizontal),
        };
    }
    getLinkEntry(dataEntry, source){
        return {
            source: source,
            target: dataEntry.name + ' ' + dataEntry.value + '%',
            value: dataEntry.value
        };     
    }
    getDataAndLinkOptionsForSankeyChart(seriesData,isHorizontal,source = 'Revenue TTM'){
        let formatter = source.replace(/<br\/?>/g, '\n');
        let metricsEntry = {
            name: source,
            itemStyle: {
                color: '#3B7D23'
            },
            label: this.getBaseLabelForSankeyChart(formatter,isHorizontal),
        };
        let optionsData = [];
        let linksData = [];
        optionsData.push(metricsEntry);
        for (let i = 0; i < seriesData.length; i++){
            optionsData.push(this.getDataEntry(seriesData[i],isHorizontal));
            linksData.push(this.getLinkEntry(seriesData[i],source));
        }
        let options = {
            data: optionsData,
            links: linksData
        };
        return options;
    }
    getOptionsForSankeyChart(seriesData,metricName,isHorizontal = false){
        let chartOptions = this.getBaseOptionsForSankeyChart();
        if (isHorizontal == true){
            $.extend(true, chartOptions.series, this.getVerticalConfiguration(isHorizontal));
        }
        $.extend(true, chartOptions, {series: this.getDataAndLinkOptionsForSankeyChart(seriesData,isHorizontal,metricName)}); 
        return chartOptions;
    }
    getVerticalConfiguration(){
        return {
            orient: 'horizontal',
            width: '65%',
            height: '70%',    
            nodeGap: 8,            
        };
    }
    getBaseOptionsForSankeyChart(){
          return {
              series: {
                  type: 'sankey',
                  layout: 'none',
                  emphasis: {
                    focus: 'adjacency',
                    color: '#a6a6a6'
                  },
                  lineStyle: {
                    color: '#B4E5A2',
                    opacity: 1,
                  }, 
                  width: '90%',
                  height: '70%',
                  nodeWidth:10,
                  nodeGap: 40,
                  orient: 'vertical',
              }
          };
    } 
    /* Sankey Parser Functions end */
    setSeriesData(chartOptions, seriesData){
        for (var pricingPowerSeriesIndex = 0; pricingPowerSeriesIndex < seriesData.series.length; pricingPowerSeriesIndex++) {
            let baseSeries = this.getBaseBarServiceForChart();
            baseSeries.color = seriesData.series[pricingPowerSeriesIndex].color;
            baseSeries.label.color = seriesData.series[pricingPowerSeriesIndex].label.color;
            baseSeries.data = seriesData.series[pricingPowerSeriesIndex].data;
            let dataList = seriesData.series[pricingPowerSeriesIndex].data;
            let data = dataList[0];
            baseSeries.label.formatter = this.buildFormatter(data);
            baseSeries.label.rich.c.color = data.dataValueColor;
            chartOptions.series.push(baseSeries);
        }      
        return chartOptions;
    }   
    buildFormatter(data){
        let isSingleLine = data.value < 10;
        let format = [];
        let labelFormat = '{b|' + data.name + '}';
        let dataFormat = '{c|' + data.value +'%'+''+'}';
        format.push(labelFormat);
        format.push(dataFormat);
        if (isSingleLine == true){
            return format.join(' ');
        } 
        return format.join('\n');
    }
    getDefaultOptions(){
        return {
            series: [],
            yAxis: this.getBaseYAxis(),
        };        
    }
    getBaseYAxis(){
        return {
            type: 'value',
            show: false,
            min: 0,
            max: 100,
                    data: []           
        };
    }
    getBaseBarServiceForChart(){
        return {       
            type: 'bar',
            stack: 'total',
            color: '#548235',
            label: {
                show: true,
                fontSize: 10,
                //verticalAlign: 'top',
                fontWeight: 'bold',
                fontFamily: 'Franklin Gothic Medium Cond',
                color: '#000',
                formatter: '{b} {c}%',
                rich: {
                 c: {
                    fontFamily: "Franklin Gothic Medium Cond",
                    fontSize: 10,
                    color: '#000000'
                  },
                },
                position: 'right',
            },
            emphasis: {
                focus: 'series'
            },
            itemStyle: {
                borderColor: '#fff',
                borderWidth: 2,
                borderType: 'solid'
            },      
            barWidth: 100,
            data: [],
        }
    }
    getBaseOptionsForChart(){
        return {
            legend: {
                show: false
            },
            grid: {
                top: '0%',
                left: '0%',
                right: '0%',
                bottom: '0%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                min:0,
                max:100,            
                axisLabel: { show: false },
                axisTick: { show: false },
                axisLine: { show: false },
                splitLine: {
                show: false
                },
            }
        };
    }
    setContributionChartList(tempContributionChartList) {
        let contributionChartList = [];
        for (let chartIndex = 0; chartIndex < tempContributionChartList.length; chartIndex++) {
            if (chartIndex == 1) {
                break;
            }
            let contributionChart = tempContributionChartList[chartIndex];
            for (let individualChartIndex = 0; individualChartIndex < contributionChart.charts.length; individualChartIndex++) {
                contributionChart.charts[individualChartIndex].options =
                    this.setOptions(this.getDefaultOptions(), contributionChart.charts[individualChartIndex].periodName, contributionChart.charts[individualChartIndex].options,contributionChart.charts[individualChartIndex].header);

            }
            contributionChartList.push(contributionChart);
        }

        return contributionChartList;
    }
    getDefaultTextStyle(){
        return {
            fontSize: 12,
            fontFamily: "Franklin Gothic Medium Cond",
        }
    }
    getBaseEChartCriteria(){
        return {
            showLabels: true,  
            showLegend: true,
            colorPaletteId: 0,
            eChartType : "stacked-bar-vertical"
          };        
    }

}
export default new EChartsService();
