<template>
  <div id="dashboard-value-road-map" :class="getClass(dashboardLayoutCard.dashboardCardTypeCode, dashboardLayoutCard.sequenceNumber)">
    <div class="dashboard-header">
      <div class="dashboard-title">
        <div class="dashboard-title-details">
          <a href="/analytics/profiles" @click="$parent.logViewDetailsEvent(title)">            
            Road Map
          </a>
          <smart-center-link v-if="displayDashboard" page="Competitive Dashboard" location="Moneyball Stick" />
        </div>
        <div class="dashboard-save-dashboard-card"  @click="$modal.show(modalName)"> 
            Save for Report
            <span class="m-1">
              <font-awesome-icon :icon="['fa', 'floppy-disk']" />
            </span>
        </div>
      </div>
      <div class="dashboard-widgets dashboard-value-road-map">
        <dashboard-card-dropdown label="Map Card" :default-dropdown-option="'Data-Driven'" :read-only="readOnly" :options="by_segment_options" :default-value="mapCard" @change="saveMapCard"></dashboard-card-dropdown>
        <dashboard-card-dropdown label="Metric" :read-only="readOnly" :options="inputMetricOptions" :default-value="metricId" @change="saveMetric"></dashboard-card-dropdown>
      </div>
      <dashboard-card-expand :dashboard-id="dashboardId" />
    </div>
    <div class="dashboard-divider"></div>
    <div :id="containerId" class="dashboard-card-body">
      <dashboard-card-skeleton v-if="!displayDashboard" />
        <value-road-map 
          v-if="this.noData == false && displayDashboard == true"
              :value-road-map-data="valueRoadMapData"
        />
      <div v-if="this.noData == true && this.displayDashboard == true" class="no-data-for-chart-container" style="height: 250px;">
          <div v-show="this.noData == true" class="no-data-for-chart mx-auto">
            <div class="pb-2">Not enough data in your selection</div>
            <div class="pb-2">exists to build a digital profile.</div>
            <div class="pb-2">Try selecting a map card,</div>
            <div class="pb-2">and metric.</div>
          </div>
      </div>
    </div>
    <dashboard-modal 
      :modal-id="dashboardId" 
      :chart="this.chart" 
      :value-road-map-data="valueRoadMapData"
      :no-data="this.noData"
      :client-chart="this.clientChart" 
      :dashboard-card-report-page-type-name="reportTypeName" 
      :show-value-road-map-display="displayDashboard"
      :modal-title="title" 
       />    
    <vue-modal :ref="modalName" :name="modalName" :width="350" :height="200">
      <save-chart :modal-name="modalName" button-label="Save for Report" :chart-title="this.chartTitle" :initial-client-chart="this.clientChart" @save="this.updateClientCharts" @closeModal="closeModal(this.modalName)"></save-chart>
    </vue-modal>
  </div>
</template>

<script>
import DashboardMixins from "../../mixins/DashboardMixins.vue";
import DashboardService from "../../services/dashboard.service";
import AdminService from "../../services/admin.service";
import ClientCompetitorService from "../../services/client.competitor.service";
import ChartService from "../../services/chart.service";
import ClientService from "../../services/client.service";  
import ReportService from "../../services/report.service";  
import MoneyballStickChart from "../../views/echarts/MoneyballStickChart.vue";
import ValueRoadMap from "../../components/chart/ValueRoadMap.vue";

import SaveChart from "../chart/SaveChart.vue";
import DashboardCardDropdown from "./DashboardCardDropdown";
import DashboardCardSkeleton from "../../components/skeleton/DashboardCardSkeleton.vue";
import "echarts/lib/chart/heatmap";

export default {
  name: "DashboardValueRoadMap",
  mixins: [DashboardMixins],
  components: { SaveChart,DashboardCardSkeleton,DashboardCardDropdown,ValueRoadMap },
  data() {
    return {
      initialMapCardsLoaded: false,
      title: "Road Map",
      modalName: "value-road-map-save-chart-confirmation",     


      focusAreas: [],
      moatBarriers: [],
      initiatives: [],
      willingnessToPay: 0,
      satGapSize: 0,
      emotGapSize: 0,
      metricData: {
        name: "",
        value: 0,
        metricDirection: ""
      },
      valueRoadMapData: {},
      mapCard: "",
      metricId: "",
      useMetricGrowth: false,
      disableWidget: false,
      noData: true,
      by_segment_options: [],
    };
  },
  props: {
    inputMetricOptions: {
      type: Array,
      default: function() {
        return [];
      }
    },    
    inputMetricId: {
      type: String,
      default: ""
    },
    entryClientChart: {
      type: Object,
      default: function () {
        return {};
      },
    },
    inputClientCharts: {
      type: Array,
      default: function() {
        return [];
      }
    },
  },
  computed: {},
  mounted() {
    this.metricId = this.inputMetricId;
    this.setChartDetails();
  },
  created() {},
  methods: {
    initialLoadData: async function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardValueRoadMap::initialLoadData this.autoGenerateChartRun: " + this.autoGenerateChartRun + "  client id: " + this.entryClientChart.clientId + "  time period id: " + this.timePeriodId);
      this.setChartDetails();
      this.clearData();
      let saved_card_options = ClientService.getAllSavedChartsByChartType(this.inputClientCharts, 1);
      saved_card_options.sort(ReportService.compareValues("name", "asc"));
      this.loadSegments(saved_card_options);
      //console.log("DashboardValueRoadMap::initialLoadData saved_card_options " + JSON.stringify(saved_card_options));
      this.mapCard = DashboardService.getDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_VALUE_ROAD_MAP_CARD_TYPE, this.mapCard, this.by_segment_options);

      if (this.autoGenerateChartRun){
          this.initialMapCardsLoaded = true;
          this.processAutoGenerateChart();
      } else {
        this.metricId = this.inputMetricId;
        this.displayDashboard = true; 
      }
      this.updateCard();
    },
    loadData: function() {},
    setChartDetails(){
      this.chartTitle = "Road Map";
      this.chartTypeName = "dashboard-value-road-map";
      this.containerId = "value-road-map-snap-id";
      this.reportTypeName = "Dashboard - Value Road Map";
      this.modalName = "value-road-map-save-chart-confirmation";
    },
    updateCard: function() {
      this.resetChart();
      if (this.initialMapCardsLoaded == true){
        this.displayDashboard = false;
        let vm = this;
        setTimeout(function() {
          if (vm.timePeriodId != "") {
            vm.displayDashboard = false;
            this.disableWidget = true;
            if (!vm.autoGenerateChartRun){
                vm.metricId = DashboardService.getDashboardSetting(vm.$parent.defaultDashboardValues, DashboardService.DASHBOARD_VALUE_ROAD_MAP_METRIC_SETTING_TYPE, vm.inputMetricId, vm.inputMetricOptions);
                vm.mapCard = DashboardService.getDashboardSetting(vm.$parent.defaultDashboardValues, DashboardService.DASHBOARD_VALUE_ROAD_MAP_CARD_TYPE, vm.mapCard, vm.by_segment_options);
            }
            if (typeof vm.metricId != "undefined" && vm.metricId.includes("growth")) {
                 vm.useMetricGrowth = true;
            }
            DashboardService.getValueRoadMap(vm.timePeriodId, vm.mapCard, vm.metricId, vm.useMetricGrowth, vm.getValueRoadMapCallback, vm);
          } else {
            vm.displayDashboard = true;
          }
        }, 1000);
      }
    },
    getValueRoadMapCallback(response) {
      this.disableWidget = false;
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        if (!this.autoGenerateChartRun){
          this.setupClientChart();
        } 
        let returnData = response.data.data;    
        this.willingnessToPay = returnData.willingnessToPay;
        this.satGapSize = returnData.satGapSize;
        this.emotGapSize = returnData.emotGapSize;
        this.metricData = returnData.metricData;
        this.noData = returnData.noData;

        this.valueRoadMapData = {};
        this.valueRoadMapData.focusAreas = returnData.focusAreas;
        this.valueRoadMapData.initiatives = returnData.initiatives;
        this.valueRoadMapData.moatBarriers = returnData.moatBarriers;        
      
        this.valueRoadMapData.willingnessToPayDirection = returnData.willingnessToPayDirection;
        this.valueRoadMapData.satGapSizeDirection = returnData.satGapSizeDirection;    
        this.valueRoadMapData.emotGapSizeDirection = returnData.emotGapSizeDirection;
        
        


        this.valueRoadMapData.willingnessToPay = this.willingnessToPay
        this.valueRoadMapData.satGapSize = this.satGapSize;
        this.valueRoadMapData.emotGapSize = this.emotGapSize;
        this.valueRoadMapData.metricData = this.metricData
        this.displayDashboard = true;
        if (this.autoGenerateChartRun && !this.readOnly){
          this.minDashboardChartHeight = 0;
        }
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    loadSegments(data) {
      //console.log("DashboardValueRoadMap::loadSegments data " + JSON.stringify(data));
      this.by_segment_options = [];
      for (var i = 0; i < data.length; i++) {
        if (data[i].displayType == "value-roadmap"){
          this.by_segment_options.push({ value: data[i].clientChartId, name: data[i].name });
        }
      }
      this.initialMapCardsLoaded = true;
    },
    clearData() {
      this.noData = true;
      this.displayDashboard = false;
      this.mapCard = "";
      this.metricId = "";
    },
    processSavedChartCriteria(criteria) {
      let criteriaParameters = criteria.split("=");
      let isMapCard = criteriaParameters[0] == "mapCard" ? true : false;
      if (isMapCard){
        this.mapCard = criteriaParameters[1];
      }
      let isMetric = criteriaParameters[0] == "metric" ? true : false;
      if (isMetric){
        this.metricId = criteriaParameters[1];
      }
      let isUseMetricGrowth = criteriaParameters[0] == "useMetricGrowth" ? true : false;
  	  if (isUseMetricGrowth){
        this.useMetricGrowth = criteriaParameters[1];
     }
    },    
    buildUrlParams(client_id) {
      let params = this.buildBaseUrlParams();
      params.append("mapCard",this.mapCard);
      params.append("metric",this.metricId);
      params.append("useMetricGrowth",this.useMetricGrowth);
      return params;
    },  
    setMapCard() {
      //console.log("DashboardValueRoadMap::setMapCard mapCard " + this.mapCard + " by_segment_options " + JSON.stringify(this.by_segment_options));
      this.mapCard = DashboardService.getDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_VALUE_ROAD_MAP_CARD_TYPE, this.mapCard, this.by_segment_options);
      let mapCardFound = this.by_segment_options.find(x => x.value == Number(this.mapCard));
      if (typeof mapCardFound == "undefined") {
        this.mapCard = "";
      } 
    },
    saveMapCard: function (value) {
      this.mapCard = value;
      DashboardService.setDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_VALUE_ROAD_MAP_CARD_TYPE, this.mapCard);
      this.updateCard();
    },
    saveMetric: function(value) {
      this.metricId = value;
      this.useMetricGrowth = false;
      DashboardService.setDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_VALUE_ROAD_MAP_METRIC_SETTING_TYPE, this.metricId, this.inputMetricOptions);
      this.updateCard();
    },
  },
  watch: {
    timePeriodId: function() {
      if (this.timePeriodId != "") {
        this.initialLoadData();
      }
    },
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>