<template>
    <div :class="divClass">
        <div :id="eChartId" :class="inputClassName" :style="containerHeight">
            <chart :options="chart.options"></chart>
        </div>
        <div :id="eChartMiniId">
            <img :src="imageUrl" class="pdf-content-image-width hidden-dashboard-display"/>
        </div>             
    </div>
</template>

<script>
import ReportService from "../../services/report.service";
import ScatterService from "../../services/echarts/scatter.service";
import ProfileService from "../../services/echarts/profile.service";
import MoneyballStickService from "../../services/echarts/moneyball.stick.service";
import EChartsMixins from "../../mixins/EChartsMixins";
import $ from "jquery";

export default {
  name: "MoneyballStickChart",
  mixins: [EChartsMixins],
  components: {},
  data() {
    return {
        charts: [],
        legend : [],
        colors: [],
        emotionalNeedsNameOrder: [],
        driverNames: [],
        displayPricingPower: false,
        pricingPowerSeriesNumber: 0,
        addWrapper: false,
        maximumAxisLabelWidth: 0,
        totalCharacters: 0,
        matchType: "",



        maxPricingPower: 5,
        maxAverage: 2,
        maxYAxis: 10,

        baseSeries: MoneyballStickService.getBaseSeries(),      
        baseYSeries: MoneyballStickService.getBaseYSeries(),

        legendData: [
            "Willingness to Pay",
            "Importance Avg",
            "Satisfaction Gap Avg",
            "Emotions Gap Avg",
        ],  
        xAxisData: [],
        wtpData: [],
        importanceAvgData: [],
        satisfactionGapAvgData: [],
        emotionalGapAvgData: [],
        minChartHeight: 0,
        maxEntriesPerChart: 10,
        isDashboardCard: true,
        options: {},
        gridIncrements: {
          xIndexIncrement: 1,
          yIndexIncrement: 3,
          gridIndexIncrement: 1,
          topIncrement: 663,
          bottomIncrement: 670,
        },
        gridData: {
          gridIndex: 0,
          xIndex: 0,
          yIndex: 0,
          gridTop: 0,
          gridBottom: 0,
          seriesData: {
            wtpData: [],
            importanceAvgData: [],
            satisfactionGapAvgData: [],
            emotionalGapAvgData: [],
            xAxisData: [],            
          },
        },
    };
  },
  props: {
    isRank: {
      type: Boolean,
      default: false
    },
    inputDownloadFileName: {
      type: String,
      default: "Profile"
    },
    inputClassName: {
      type: String,
      default: "moneyball-stick-dashboard-chart e-chart"
    },
    containerHeight: {
      type: String,
      //default: "height: 250px"
      default: ""
    },
  },
  computed: {
    dashboardChartStyle: function() {
      return "height: " + this.minChartHeight + "px";
    },
    divClass: function() {
      return this.isDashboardCard ? "col-12 no-gutters container p-0" : "col-12 no-gutters container p-0 mt-3";
    }
  },
  mounted() {},
  created() {},
  methods: {
    getChartOptions: function(){
        return this.options;           
    },
    buildGridDataForRank: function(numberOfEntries){
        let returnGridData = [];
        let numberOfGrids = Math.ceil(numberOfEntries / this.maxEntriesPerChart);
        // loop through the number of grids and set the grid data
        let currentTop = ProfileService.getBaseGridTop();
        let currentBottom = this.gridIncrements.bottomIncrement;
        let startIndex = 0;
        let endIndex = this.maxEntriesPerChart;
        for (let i = 0; i < numberOfGrids; i++) {
          let gridData = JSON.parse(JSON.stringify(this.gridData));
          if (i != 0) {
            currentTop += this.gridIncrements.topIncrement;
            currentBottom += this.gridIncrements.bottomIncrement;
            gridData.gridIndex = i;
          } else {
            gridData.gridIndex = i;
          }
          gridData.gridTop = currentTop;
          if (i == numberOfGrids - 1) {
            currentBottom = '2.5%';
          } 
          gridData.gridBottom = currentBottom;
          gridData.xIndex = i;
          gridData.yIndex = i * this.gridIncrements.yIndexIncrement;


          gridData.seriesData.wtpData = this.wtpData.slice(startIndex, endIndex);
          gridData.seriesData.importanceAvgData = this.importanceAvgData.slice(startIndex, endIndex);
          gridData.seriesData.satisfactionGapAvgData = this.satisfactionGapAvgData.slice(startIndex, endIndex);
          gridData.seriesData.emotionalGapAvgData = this.emotionalGapAvgData.slice(startIndex, endIndex);
          gridData.seriesData.xAxisData = this.xAxisData.slice(startIndex, endIndex);

          // increment the start and end index by the number of entries per chart
          startIndex += this.maxEntriesPerChart;
          endIndex += this.maxEntriesPerChart;
          returnGridData.push(gridData);
        }
        return returnGridData;
    },
    setDataForMoneyballStick: function(moneyballStickChartData){
        this.xAxisData = [];
        this.wtpData = [];
        this.importanceAvgData = [];
        this.satisfactionGapAvgData = [];
        this.emotionalGapAvgData = [];
        this.maxPricingPower = moneyballStickChartData.maxWTP;
        this.maxAverage = moneyballStickChartData.maxYAxis;
        let numberOfEntries = 1
        if (typeof moneyballStickChartData.moneyballStickDataPoints != "undefined") {
          numberOfEntries = moneyballStickChartData.moneyballStickDataPoints.length;
          let moneyballStickDataPoints = moneyballStickChartData.moneyballStickDataPoints;
          for (let i = 0; i < moneyballStickDataPoints.length; i++) {
            this.xAxisData.push(moneyballStickDataPoints[i].name);
            this.wtpData.push(moneyballStickDataPoints[i].willingnessToPay);
            this.importanceAvgData.push(moneyballStickDataPoints[i].importanceAvg);
            this.satisfactionGapAvgData.push(moneyballStickDataPoints[i].satisfactionGapAvg);
            this.emotionalGapAvgData.push(moneyballStickDataPoints[i].emotionalGapAvg);
          }
        } 

        let rotate = this.isDashboardCard ? 25 : 25; 
        let toolTip = MoneyballStickService.getTooltip() ;

        let grid = this.isDashboardCard ? MoneyballStickService.getGrid(200) : ProfileService.getGridForSingleChart();
        let xAxis = MoneyballStickService.getXAxis(this.xAxisData,rotate);
        let yAxis = MoneyballStickService.getYAxis(this.maxYAxis,this.maxPricingPower,this.isDashboardCard,{});
        let series = MoneyballStickService.getSeries(this.wtpData,this.importanceAvgData,this.satisfactionGapAvgData,this.emotionalGapAvgData, this.xAxisData);

        if (this.isRank && numberOfEntries > this.maxEntriesPerChart) {
          grid = [];
          xAxis = [];
          let finalGridData = this.buildGridDataForRank(numberOfEntries);
         // console.log("finalGridData: " + JSON.stringify(finalGridData));
          // loop through finalGridData and create the grid
          for (let i = 0; i < finalGridData.length; i++) {
            let gridData = finalGridData[i];
            let gridEntry = MoneyballStickService.getGridForMoneyRank(gridData.gridTop, gridData.gridBottom);
            grid.push(gridEntry);
            let xAxisDataForEntry = gridData.seriesData.xAxisData;
            let xAxisEntry = MoneyballStickService.getXAxis(xAxisDataForEntry,rotate,gridData.gridIndex);          
            xAxis.push(xAxisEntry);
          }
          yAxis = MoneyballStickService.getYAxis(this.maxYAxis,this.maxPricingPower,this.isDashboardCard,finalGridData);
          series = MoneyballStickService.getSeries(this.wtpData,this.importanceAvgData,this.satisfactionGapAvgData,this.emotionalGapAvgData, this.xAxisData,finalGridData);          
        //  console.log("grid: " + JSON.stringify(grid));
        //  console.log("yAxis: " + JSON.stringify(yAxis));
/*
          // Add grid
          let currentTop = 663;
          grid[0].bottom = 670;
          let addedGrid = MoneyballStickService.addGrid(currentTop);
          addedGrid.bottom = '2.5%';
          grid.push(addedGrid);
          // Add yAxis
          let copyOfYAxis = JSON.parse(JSON.stringify(yAxis));
          for (let i = 0; i < copyOfYAxis.length; i++) {
            copyOfYAxis[i].gridIndex = 1;
            yAxis.push(copyOfYAxis[i]);
          }
*/
/*
          xAxis = MoneyballStickService.getXAxis(this.xAxisData,rotate,finalGridData);          
          // Add xAxis
          let copyOfXAxis = JSON.parse(JSON.stringify(xAxis));
          let newXAxis = [];
          newXAxis.push(xAxis);
          $.extend(true, copyOfXAxis, gridIndex);
          newXAxis.push(copyOfXAxis);
          xAxis = JSON.parse(JSON.stringify(newXAxis));
          // Add series
          let copyOfSeries = JSON.parse(JSON.stringify(series));
          for (let i = 0; i < copyOfSeries.length; i++) {
            copyOfSeries[i].xAxisIndex += this.gridIncrements.xIndexIncrement;
            copyOfSeries[i].yAxisIndex += this.gridIncrements.yIndexIncrement;
            $.extend(true, copyOfSeries[i], gridIndex );
            series.push(copyOfSeries[i]);
          }
*/

        } else if (this.isDashboardCard == false) {
          grid[0].bottom = '2.5%';
        }
        /*
        if (this.xAxisData.length >= this.maxEntriesPerChart){
          series[0].barWidth = null;
        }
          */
        let color = MoneyballStickService.getColor();
        
        ScatterService.setYAxis(yAxis); 
        ScatterService.setXAxis(xAxis);
        ScatterService.setGrid(grid);
        ScatterService.setSeries(series);
        if (this.isDashboardCard == false){
          ScatterService.setLegendData(this.legendData);
        }
        this.options = ScatterService.getOptions(this,this.modalId, this.inputReportTypeName,this.inputDownloadFileName);
        this.options.series = series;
        this.options.tooltip = toolTip;
        this.options.tooltip.formatter =  MoneyballStickService.toolTipForMoneyballStick;       
        this.options.color = color;

        if (this.isDashboardCard == false){
          this.options.grid[0].left = 90;
          this.options.grid[0].top = 10;
          this.options.legend.top = 10;

        } else {
          this.options.legend = MoneyballStickService.getLegend(this.legendData);
          this.options.toolbox = {}     
          this.options.yAxis[0].splitNumber =  5;
          this.options.yAxis[0].min = 5;
          this.options.yAxis[1].splitNumber =  5;
          this.options.yAxis[1].min = 5;
        }
    },
    processChart: function(runChart = false,moneyballStickChartData,isDashboard = false,autoGenerateChartRun = false){
        if (runChart == true) {
          this.isDashboardCard = isDashboard;
          if (this.isDashboardCard== true){
            this.minChartHeight = 250;
          } else {
            this.minChartHeight = 600;
          }
            this.setDataForMoneyballStick(moneyballStickChartData);
            this.initChart(moneyballStickChartData);
            if (this.isDashboardCard== false){
              this.$emit('save-criteria',true);
              this.$emit('save-table');
            }  else if (autoGenerateChartRun == true){
              this.saveImage(true);
            }              
        }
    },    
  },
  watch: {},
};
</script>
