<template>
  <div class="saved-cards-set">
    <div class="save-chart title mt-3">
      <span v-if="isSavedCardsSetForSurvey" class="align-middle">Cards: </span>
      <span v-if="!isSavedCardsSetForSurvey" class="align-middle">Organization Cards:</span>
      <span class="align-middle pl-2">{{ setName }}</span>
<!--      <img src="/img/The-W-Report-logo-25.png" /> -->
      <span class="wizard-icon float-right">
        <!--
          <font-awesome-icon v-tooltip="tooltipConfigShow" :icon="['fas', 'hat-wizard']" @click="$root.toggleWizardContent()"/>
        -->
        <i class="fa-duotone fa-hat-wizard" aria-hidden="true" @click="$root.toggleWizardContent()"></i>
      </span>
    </div>
    <div v-show="$root.getShowWizardContent()" class="wizard-content">
      <div class="wizard-content-column wizard-border col-4">
        <div class="circle">1</div>
        <div class="wizard-text">
          Cards for The W Report™ are saved 
          versions of charts, tables and data 
          from Analytics side menu. Click on the 
          View drop-down to sort your Cards, or 
          push the Detailed View button to see 
          your Cards in a list. 
        </div>
      </div>
      <div class="wizard-content-column wizard-border col-4">
        <div class="circle">2</div>
        <div class="wizard-text">
            Click any Card to view its data. Return 
            to this view my clicking Competitive 
            Dashboard in the menu sidebar.          
        </div>
      </div>
      <div class="wizard-content-column col-4">
        <div class="circle">3</div>
        <div class="wizard-text">
          To include a Card in The W Report™, 
          click the top-left checkbox. When done 
          selecting Cards, click Report Setup to 
          tailor your report before printing.
        </div>
      </div>
    </div>
    <div class="row mt-3 mb-3 ml-1 mr-1">
      <div v-show="!isDashboardView && isSavedCardsSetForOrganization" class="col-3">
        <div class="show-hide-wrapper">
          <span class="saved-cards-label">Hide</span>
          <toggle-button v-model="showCardsToggleValue" :value="showCards" :sync="true" color="rgb(191, 203, 217)" :speed="100" @change="onChangeEventHandler"/>
          <span class="saved-cards-label">Show Cards</span>
        </div>
      </div>
      <div class="col-xl-4 col-md-6">
        <span class="saved-cards-label">View</span>
        <select id="sortBy" name="sortBy" class="custom-select d-inline w-75 required" v-model="sortBy">
          <option value>Sort By</option>
          <option v-for="(sort_by_option, idxSortByOptions) in this.sort_by_options" :value="sort_by_option.value" :key="idxSortByOptions">
            {{ sort_by_option.name }}
          </option>
        </select>
      </div>
      <div v-show="!isDashboardView" class="col-3">
        <div class="show-hide-wrapper">
          <span class="saved-cards-label">Card View</span>
          <toggle-button v-model="useDetailedView" :value="useDetailedView" :sync="true" color="rgb(191, 203, 217)" />
          <span class="saved-cards-label">List View</span>
        </div>  
      </div>
      <div class="col justify-content-end text-right">
        <button v-show="!isDashboardView" type="button" class="btn btn-primary" v-on:click="goToPrintReport">
          <span class="align-text-bottom"><font-awesome-icon :icon="['fa', 'images']"/><span style="padding-left: 5px">Report Setup</span></span>
        </button>
      </div>
    </div>
    <div v-show="useDetailedView && showCards" class="row">
      <div class="col">
        <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
          <thead></thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
    <div v-show="!useDetailedView && showCards" class="w-100 pr-3">
      <div class="d-flex justify-content-between">
        <div></div>
        <div v-show="!isDashboardView" class="form-group form-inline">
          <label for="search" class="pr-2">Search:</label>
          <input v-model="searchText" id="search" name="search" type="text" class="ml-2 form-control form-control-sm" />
        </div>
      </div>
    </div>
    <div v-if="!useDetailedView && showCards" class="row no-gutters pl-3 pr-3">
      <div :class="isDashboardView ? 'col-3' : 'col-2'" v-for="(clientChart, idxClientChart) in this.displayedSavedCards" :key="idxClientChart">
        <chart-card :chart-card-object="clientChart" :clientChartIds="$parent.clientChartIds" :setType="setType" :viewType="viewType" @delete="$parent.resetClientCharts" @update="updateCardListForReport"/>
      </div>
    </div>
    <dashboard-moneyball-stick-modal 
          :modal-id="setType+'-moneyball-stick'"
          :modal-title="dashboardCardModalTitle"
          :client-object="dashboardClientObject" 
          :save-client-chart-object="dashboardSavedClientChart"/>
    <dashboard-moat-comps-modal 
          :modal-id="setType+'-moat-comps'"
          :modal-title="dashboardCardModalTitle"
          :client-object="dashboardClientObject" 
          :save-client-chart-object="dashboardSavedClientChart"/>
    <dashboard-needs-profile-modal 
          :modal-id="setType+'-needs-profile'"
          :modal-title="dashboardCardModalTitle"
          :client-object="dashboardClientObject" 
          :save-client-chart-object="dashboardSavedClientChart"/>
      <dashboard-growth-sequence-modal 
          :modal-id="setType+'-growth-sequence'"
          :modal-title="dashboardCardModalTitle"
          :client-object="dashboardClientObject" 
          :save-client-chart-object="dashboardSavedClientChart"/>
      <dashboard-winners-map-modal 
          :modal-id="setType+'-winners-map'"
          :modal-title="dashboardCardModalTitle"
          :client-object="dashboardClientObject" 
          :save-client-chart-object="dashboardSavedClientChart"/>
      <dashboard-nps-trends-modal 
          :modal-id="setType+'-nps-trends'"
          :modal-title="dashboardCardModalTitle"
          :client-object="dashboardClientObject" 
          :save-client-chart-object="dashboardSavedClientChart"/>
      <dashboard-competitive-trends-modal 
          :modal-id="setType+'-dashboard-competitive-trends'"
          :modal-title="dashboardCardModalTitle"
          :client-object="dashboardClientObject" 
          :save-client-chart-object="dashboardSavedClientChart"/>
      <dashboard-competitive-rank-modal 
          :modal-id="setType+'-competitive-rank'"
          :modal-title="dashboardCardModalTitle"
          :client-object="dashboardClientObject" 
          :save-client-chart-object="dashboardSavedClientChart"/>
      <dashboard-respondent-map-modal 
          :modal-id="setType+'-respondent-map'"
          :modal-title="dashboardCardModalTitle"
          :client-object="dashboardClientObject" 
          :save-client-chart-object="dashboardSavedClientChart"/>
      <dashboard-performance-trends-modal 
          :modal-id="setType+'-performance-trends'"
          :modal-title="dashboardCardModalTitle"
          :client-object="dashboardClientObject" 
          :save-client-chart-object="dashboardSavedClientChart"/>
<!--          
      <dashboard-saved-card-predictive-analytics-modal 
          :modal-id="setType+'-dashboard-predictive-analytics'"
          :modal-title="dashboardCardModalTitle"
          :client-object="dashboardClientObject" 
          :save-client-chart-object="dashboardSavedClientChart"/>
-->
      <dashboard-saved-card-predictive-analytics-contributions-modal 
          :modal-id="setType+'-dashboard-predictive-analytics'"
          :modal-title="dashboardCardModalTitle"
          :client-object="dashboardClientObject" 
          :save-client-chart-object="dashboardSavedClientChart"/>

      <dashboard-saved-card-predictive-analytics-contributions-modal 
          :modal-id="setType+'-dashboard-metric-contributions'"
          :modal-title="dashboardCardModalTitle"
          :client-object="dashboardClientObject" 
          :save-client-chart-object="dashboardSavedClientChart"/>

      <dashboard-rank-modal 
          :modal-id="setType+'-dashboard-nps-rank'"
          :modal-title="dashboardCardModalTitle"
          :client-object="dashboardClientObject" 
          :save-client-chart-object="dashboardSavedClientChart"/>

      <dashboard-rank-modal 
          :modal-id="setType+'-dashboard-moat-score-rank'"
          :modal-title="dashboardCardModalTitle"
          :client-object="dashboardClientObject" 
          :save-client-chart-object="dashboardSavedClientChart"/>
          
      <dashboard-rank-modal 
          :modal-id="setType+'-dashboard-w-score-rank'"
          :modal-title="dashboardCardModalTitle"
          :client-object="dashboardClientObject" 
          :save-client-chart-object="dashboardSavedClientChart"/>

  
  </div>
</template>

<script>
import $ from "jquery";
import AdminService from "../services/admin.service";
import AlertService from "../services/alert.service";
import UserSettingsService from "../services/user.settings.service";
import ContentService from "../services/content.service";
import ClientChartsService from "../services/client.chart.service";
import ChartService from "../services/chart.service";
import ReportService from "../services/report.service";
import ChartCard from "../components/chart/ChartCard.vue";
import DataTableMixins from "../mixins/DataTableMixins.vue";
import ViewMixins from "../mixins/ViewMixins.vue";
import ClientChartService from "../services/client.chart.service";
import DashboardNeedsProfileModal from "../modals/DashboardNeedsProfileModal.vue";
import DashboardGrowthSequenceModal from "../modals/DashboardGrowthSequenceModal.vue";
import DashboardWinnersMapModal from "../modals/DashboardWinnersMapModal.vue";
import DashboardNpsTrendsModal from "../modals/DashboardNpsTrendsModal.vue";
import DashboardCompetitiveTrendsModal from "../modals/DashboardCompetitiveTrendsModal.vue";
import DashboardCompetitiveRankModal from "../modals/DashboardCompetitiveRankModal.vue";
import DashboardRankModal from "../modals/DashboardRankModal.vue";
import DashboardRespondentMapModal from "../modals/DashboardRespondentMapModal.vue";
import DashboardPerformanceTrendsModal from "../modals/DashboardPerformanceTrendsModal.vue";
import DashboardSavedCardPredictiveAnalyticsContributionsModal from "../modals/DashboardSavedCardPredictiveAnalyticsContributionsModal.vue";
import DashboardMoatCompsModal from "../modals/DashboardMoatCompsModal.vue";
import DashboardMoneyballStickModal from "../modals/DashboardMoneyballStickModal.vue";

export default {
  name: "SavedCardsSet",
  mixins: [DataTableMixins, ViewMixins],
  components: {
    ChartCard,
    DashboardNeedsProfileModal,DashboardGrowthSequenceModal,DashboardWinnersMapModal,
    DashboardNpsTrendsModal,DashboardCompetitiveTrendsModal,DashboardCompetitiveRankModal,
    DashboardRespondentMapModal,DashboardPerformanceTrendsModal,
    DashboardSavedCardPredictiveAnalyticsContributionsModal,DashboardRankModal,DashboardMoatCompsModal,DashboardMoneyballStickModal
  },
  data() {
    return {
      cardsToShow: 4,
      searchText: "",
      showCardsToggleValue: false,
      savedCardTableBuilt: false,
      showCards: true,
      useDetailedView: false,
      sortBy: "date-added-newest",
      dashboardCardModalTitle: "",
      dashboardSavedClientChart: {},
      dashboardClientObject: {},
      deleteClientChartId: 0,
    	deleteClientChartName: "",
      deleteConfirmationTitle: "",
      table: {
        id: "saved-card-data-" + this.setType,
        class: "table display wrap row-border table-hover",
        configuration: {
          dom: '<"top"if>rtp<"bottom"><"clear">',
          searching: true,
          paging: true,
          select: true,
          destroy: true,
          info: false,
          ordering: true,
          bSort: true,
          bSortable: true,
          keys: true,
          autoWidth: false,
          processing: true,
          responsive: false,
        },
        columns: ClientChartsService.getSavedCardTableColumnData()
      },
      tableSortBy: [10, "desc"],
      sort_by_options: ClientChartsService.getSortByOptions(),
      tooltipConfigShow: ContentService.getWizardTooltip(),    
      displayedSavedCards: [],
      mutableSavedCardsObject: this.originalSavedCards,
    };
  },
  props: {
    originalSavedCards: {
      type: Array,
      default: function() {
        return [];
      }
    },
    entryClientObject: {
      type: Object,
      default: function() {
        return {};
      }
    },    
    setName: {
      type: String,
      default: ""
    },
    viewType: {
      type: String,
      default: "cards"
    },    
    setType: {
      type: String,
      default: ""
    }
  },
  computed: {
    isDashboardView: function() {
      return this.viewType ==  "dashboard"
    },
    isSavedCardsSetForSurvey: function() {
      return this.setType == "survey";
    },
    isSavedCardsSetForOrganization: function() {
      return this.setType == "organization";
    }
  },
  mounted() {
    this.sortBy = UserSettingsService.getSortByForSavedCards();
    this.showCards = this.isSavedCardsSetForSurvey;
    if (this.showCards == true){
      this.buildSavedCardTable();
    }
  },
  created() {},
  methods: {
    goToPrintReport: function() {
      let inputIds = this.$parent.clientChartIds.map(clientChart => clientChart.clientChartId);
      this.$router.replace({ name: 'print-report', params: { clientChartIdsInput: inputIds.join(',') } });
    },    
    updateCardListForReport: function(result){
      this.$parent.clientChartIds = JSON.parse(JSON.stringify(result));
    },    
    onChangeEventHandler: function() {
      if (this.showCardsToggleValue == true) {
        AdminService.displayInfoMessage({ text: "Loading cards please stand by......",duration: -1 });
        let vm = this;
        setTimeout(() => {
          vm.showCards = true;
          vm.buildSavedCardTable();
          AlertService.closeAlert();
        }, 500);        
      } else {
        this.showCards = false;
      }
    },
    getSavedCardClass: function(){
      if (this.isDashboardView){
        return "col-3";
      }
      return "col-2";
    },
    openSavedDashboardCard(dashboardId){
      this.$modal.show(dashboardId);
    },
    getDisplayedSavedCards(){
      if (this.isDashboardView){
        let pinnedSavedCards = this.mutableSavedCardsObject.filter(savedCard => {return savedCard.pin == "Y";});
        let unpinnedSavedCards = this.mutableSavedCardsObject.filter(savedCard => {return savedCard.pin == "N";});
        this.displayedSavedCards = JSON.parse(JSON.stringify(pinnedSavedCards)); 
        this.displayedSavedCards.push(...JSON.parse(JSON.stringify(unpinnedSavedCards)));
        this.displayedSavedCards = this.displayedSavedCards.slice(0,this.cardsToShow);
      } else {
        this.displayedSavedCards = JSON.parse(JSON.stringify(this.mutableSavedCardsObject)); 
      }
    },
    buildSavedCardTable() {
      if (this.savedCardTableBuilt == true) {
        return;
      }
      this.getDisplayedSavedCards();
      this.useDetailedView = UserSettingsService.getDetailedViewForSavedCards(this.displayedSavedCards);      
      if (this.isDashboardView){
        this.useDetailedView = false;
      }
      let response = {
        data: {
          data: {}
        }
      };
      this.table.configuration.order = this.tableSortBy;
      response.data.data = this.displayedSavedCards;
      // Set the survey groups
      //SurveyGroupService.setSurveyGroupListByClient();

      this.getTableDataCallback(response);
      let vm = this;
      let tableName = "saved-card-data-" + this.setType + " tbody";
      let table = $("#" + tableName);

      table.on("click", 'input[type="checkbox"]', function(e, dt, type, indexes) {
        var $row = $(this).closest("tr");
        var data = vm.table.dataTable.row($row).data();
        if (!$.isEmptyObject(data)) {
          if (e.target.checked) {
            vm.$parent.clientChartIds.push(data);
          } else {
            var match = vm.$parent.clientChartIds.find(x => x.clientChartId === data.clientChartId);
            if (typeof match !== "undefined") {
              vm.$parent.clientChartIds.splice($.inArray(match, vm.$parent.clientChartIds), 1);
            }
          }
        }
      });
      table.on("click", 'button[class="btn btn-primary delete-card"]', function(e, dt, type, indexes) {
        var $row = $(this).closest("tr");
        var data = vm.table.dataTable.row($row).data();
        if (!$.isEmptyObject(data)) {
          vm.deleteConfirmationTitle = data.name;
          vm.deleteClientChartId = data.clientChartId;
          vm.deleteClientChartName = data.name;
          AlertService.showConfirmationMessageWithParameter(vm.deleteClientChart,vm.deleteClientChartId,'Delete Confirmation Card','Are you sure you want delete ' + vm.deleteClientChartName + ' ?');            
        }
      });
      table.on("click", 'button[class="btn btn-primary view-card"]', function(e, dt, type, indexes) {
        var $row = $(this).closest("tr");
        var data = vm.table.dataTable.row($row).data();
        if (!$.isEmptyObject(data)) {
          let chartType = ChartService.findChartTypeById(data.chartTypeId);
          if (ReportService.isSavedDashboardCard(data.chartTypeId)){
            vm.dashboardSavedClientChart = JSON.parse(JSON.stringify(data));
            vm.dashboardCardModalTitle = data.name;
            vm.dashboardClientObject = {};
            vm.$modal.show(vm.setType+'-'+chartType.shortName);
          } else {
            let saveClientChartId = data.clientChartId
            vm.$router.push({ name: chartType.shortName, params: { autoGenerateChart: true, saveClientChartId: saveClientChartId } });
          }
        }
      });
      this.savedCardTableBuilt = true;
    },
    deleteClientChartCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.$parent.resetClientCharts(this.deleteClientChartId);
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async deleteClientChart(result) {
      let clientChartId = result;
      this.$root.processServerRequest("Removing saved card.");
      ClientChartService.deleteClientChart(clientChartId,this.deleteClientChartCallback,this);
    },    
    saveUserSettingCallback: function (response) {
      UserSettingsService.saveAllUserSettings(response.data.data.userSettings);
    }
  },
  watch: {
    searchText: function(){
      this.displayedSavedCards = ClientChartService.filterSavedCardsByName(this.mutableSavedCardsObject, this.searchText);
    },
    originalSavedCards: function() {
      this.mutableSavedCardsObject = this.originalSavedCards;
      this.savedCardTableBuilt = false;
      if (this.showCards == true) {
        this.buildSavedCardTable();
      }
    },
    useDetailedView: function() {
      if (this.useDetailedView == true && this.showCards == true) {
        this.table.dataTable.order(this.tableSortBy).draw();
      }
      UserSettingsService.saveUserSetting("Detailed View - Saved Cards", this.useDetailedView ? "Y" : "N", 0, this.saveUserSettingCallback, this);
    },
    sortBy: function() {
      let sortByForSavedCards = UserSettingsService.getUserSetting("Sort By - Saved Cards");
      if (sortByForSavedCards != this.sortBy) {
        UserSettingsService.saveUserSetting("Sort By - Saved Cards", this.sortBy, 0, this.saveUserSettingCallback, this);
      }
      if (this.sortBy == "name-ascending") {
        this.tableSortBy = [3, "asc"];
        this.mutableSavedCardsObject.sort(ReportService.compareValues("name", "asc"));
      } else if (this.sortBy == "name-descending") {
        this.tableSortBy = [3, "desc"];
        this.mutableSavedCardsObject.sort(ReportService.compareValues("name", "desc"));
      } else if (this.sortBy == "date-added-newest") {
        this.mutableSavedCardsObject.sort(ReportService.compareValues("dateCreated", "desc"));
        this.tableSortBy = [10, "desc"];
      } else if (this.sortBy == "date-added-oldest") {
        this.mutableSavedCardsObject.sort(ReportService.compareValues("dateCreated", "asc"));
        this.tableSortBy = [10, "asc"];
      } else if (this.sortBy == "survey-ascending") {
        this.mutableSavedCardsObject.sort(ReportService.compareValues("surveyName", "asc"));
        this.tableSortBy = [6, "asc"];
      } else if (this.sortBy == "survey-descending") {
        this.mutableSavedCardsObject.sort(ReportService.compareValues("surveyName", "asc"));
        this.tableSortBy = [6, "desc"];
      } else if (this.sortBy == "organization-ascending") {
        this.mutableSavedCardsObject.sort(ReportService.compareValues("clientName", "asc"));
        this.tableSortBy = [5, "asc"];
      } else if (this.sortBy == "organization-descending") {
        this.mutableSavedCardsObject.sort(ReportService.compareValues("clientName", "asc"));
        this.tableSortBy = [5, "desc"];
      }
      this.getDisplayedSavedCards();
      if (this.useDetailedView == true && this.showCards == true) {
        this.table.dataTable.order(this.tableSortBy).draw();
      }
    }
  }
};
</script>
