<template>
  <div class="value-road-map-setup">
    <h3 class="text-primary pt-3">Road Map</h3>
    <div class="value-road-map-header">    
        <div>Drag and drop the functional or emotional needs to Focus Areas; Specify the Initiatives that connect to the Focus Areas.</div>
    </div>
    <div class="form-row">
            <div class = "value-road-other-area">
                <div class="d-flex justify-content-between">
                    <div>
                        <h4 class="text-primary">Other Areas</h4>
                        <div class="description">Areas of value that are non-distinctive</div>
                    </div>
                    <div class="d-flex btn-group-horizontal align-items-center pl-2 pt-3">
                        <div>
                            <button type="submit" class="btn btn-primary float-right mr-2" :disabled="!previousEffortSet.enablePreviousButton" @click="switchToPrevious()">
                            <span>Undo</span>
                            </button>
                        </div>
                        <div>
                            <button type="submit" class="btn btn-primary float-right mr-2"  @click="switchToDefault('Default')">
                                <span>Data-Driven Picks</span>
                            </button>
                        </div>
                    </div>
                </div>
                <VueDraggable
                    v-model="otherAreaEfforts"
                    :disabled="!enabled"
                    class="list-group draggable-container"
                    group="bucket"
                    ghost-class="draggable-ghost"
                    :move="checkMove"
                    :sort="true"
                    @start="dragging = true"
                    @end="endOfDragOtherAreaEfforts"
                    options="people"
                    >
                    <div class="list-group-item draggable-card" v-for="element in otherAreaEfforts" :key="element.name" :index="element.name">
                      <font-awesome-icon :icon="['fas', 'grip-vertical']" />
                      <span :class="element.needType + ' pl-1'">{{ element.name }}</span>
                    </div>
                </VueDraggable>                
                
            </div>
            <div class = "value-road-map-area">
                <h4 class="text-primary">Focus Areas</h4>
                <div class="description">Areas of value to emphasize</div>
                <VueDraggable
                    v-model="focusAreaEfforts"
                    :disabled="!enabled"
                    class="list-group draggable-container"                   
                    group="bucket"
                    ghost-class="draggable-ghost"
                    :move="checkMove"
                    :sort="true"
                    @start="dragging = true"
                    @end="endOfDragFocusAreaEfforts"
                    options="people"
                    >
                    <div class="list-group-item draggable-card" v-for="element in focusAreaEfforts" :key="element.name" :index="element.name">
                        <i class="fa fa-caret-right" aria-hidden="true"></i>
                        <span :class="element.needType + ' pl-1'">{{ element.name }}</span>
                    </div>
                </VueDraggable>    
                            
            </div>
            <div class = "value-road-map-initiatives">
                <h4 class="text-primary">Initiatives</h4>
                <div class="description">Programs to create and capture more value</div>
                <div v-for="(initiative, idxInitiative) in initiatives" v-bind:key="idxInitiative" class="form-row form-group">
                    <input
                        v-model="initiative.initiative"
                        :id="initiative.valueRoadMapInitiativeId"
                        :name="initiative.name"
                        :placeholder="'Initiative ' + (idxInitiative + 1)"
                        type="text"
                        class="form-control"
                        style="width: 100%"
                        />
                </div>
            </div>
    </div>    
  </div>
  
</template>
<script>
//import WinnersService from "../../services/chart/winners.service";
import AdminService from "../../services/admin.service";
import ValueGroupMapService from "../../services/chart/value.group.map.service"
import { VueDraggable } from 'vue-draggable-plus';
import $ from "jquery";

export default {
  name: "ValueRoadMapSetup",
  mixins: [],
  components: { VueDraggable },
    props: {
    surveyCode: {
      type: String,
      default: ""
    },
    clientChartObject: {
      type: Object,
      default: function() {
        return {
          clientChartId: 0
        };
      }
    }
  },
  data() {
    return {
      valueRoadMapId: 0,
      enabled: true,
      focusAreaEfforts: [],
      otherAreaEfforts: [],
      previousEffortSet: {
        name : "Previous", isPopulated: false, enablePreviousButton: false
      },
      initiatives: [
        {valueRoadMapInitiativeId: 0, valueRoadMapId: 0, initiative: "", sequenceNumber: 0},
        {valueRoadMapInitiativeId: 0, valueRoadMapId: 0, initiative: "", sequenceNumber: 1},
        {valueRoadMapInitiativeId: 0, valueRoadMapId: 0,  initiative: "", sequenceNumber: 2},
        {valueRoadMapInitiativeId: 0, valueRoadMapId: 0,  initiative: "", sequenceNumber: 3},
        {valueRoadMapInitiativeId: 0, valueRoadMapId: 0, initiative: "", sequenceNumber: 4},
      ],
      defaultEffortSets: [
        {name: "Manual", focusAreaEfforts: [], otherAreaEfforts: [] },
      ]
    };
  },
  methods: {
    getChartCardClass() {
      let returnClassName = "chart-card-row chart-card-name ";
      return returnClassName;
    },
    savePrevious: function(defaultTypeName){
      if (!this.previousEffortSet.isPopulated){
        this.previousEffortSet.focusAreaEfforts = this.focusAreaEfforts;
        this.previousEffortSet.otherAreaEfforts = this.otherAreaEfforts;
        this.previousEffortSet.isPopulated =  true;
      }
      if(this.previousEffortSet.isPopulated){
        this.previousEffortSet.enablePreviousButton = true;
      }
    },
    switchToPrevious: function(){
      if (!$.isEmptyObject(this.previousEffortSet)) {
        let focusAreaEfforts = JSON.parse(JSON.stringify(this.previousEffortSet.focusAreaEfforts));
        let otherAreaEfforts = JSON.parse(JSON.stringify(this.previousEffortSet.otherAreaEfforts));
        if (!this.isDefaultTypeEmpty(focusAreaEfforts,otherAreaEfforts)){
          this.setEffortSet(focusAreaEfforts,otherAreaEfforts);
          this.previousEffortSet.enablePreviousButton = false;
        }
      }
    },
    switchToDefault: function(defaultTypeName){
      let defaultEffortSet = this.defaultEffortSets.find(x => x.name == defaultTypeName)
      if (!$.isEmptyObject(defaultEffortSet)) {
        let focusAreaEfforts = JSON.parse(JSON.stringify(defaultEffortSet.focusAreaEfforts));
        let otherAreaEfforts = JSON.parse(JSON.stringify(defaultEffortSet.otherAreaEfforts));
        if (!this.isDefaultTypeEmpty(focusAreaEfforts,otherAreaEfforts)){
          this.savePrevious(defaultTypeName);
          this.setEffortSet(focusAreaEfforts,otherAreaEfforts);
        }
      }
    },    
    endOfDragOtherAreaEfforts: function(event) {},
    endOfDragFocusAreaEfforts: function(event) {},
    
    checkMove: function(e) {
    },
    setupValueGroupMapSetupForSave: function() {
      for (let focusAreaEffortsIndex = 0; focusAreaEffortsIndex < this.focusAreaEfforts.length; focusAreaEffortsIndex++) {
        this.focusAreaEfforts[focusAreaEffortsIndex].sequenceNumber = focusAreaEffortsIndex + 1;
      }
      for (let otherAreaEffortsIndex = 0; otherAreaEffortsIndex < this.otherAreaEfforts.length; otherAreaEffortsIndex++) {
        this.otherAreaEfforts[otherAreaEffortsIndex].sequenceNumber = otherAreaEffortsIndex + 1;
      }
    },
    saveWinnersMap: async function() {
      this.setupValueGroupMapSetupForSave();
      ValueGroupMapService.saveValueRoadMapSetup(this.surveyCode, this.focusAreaEfforts, this.valueRoadMapId,this.saveValueRoadMapSetupCallback, this) 
    },
    saveValueRoadMapSetupCallback: function(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        AdminService.displayInfoMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    loadData: function() {
      if (this.surveyCode != "") {
        ValueGroupMapService.getValueRoadMapSetupBySurveyCode(this.surveyCode,  this.clientChartId, this.loadDataCallback, this);
      }
    },
    loadDataCallback: function(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        let focusAreaEfforts = response.data.data.focusAreaEfforts;
        let otherAreaEfforts = response.data.data.otherAreaEfforts;
        this.defaultEffortSets = response.data.data.defaultEffortSets;
        this.valueRoadMapId = response.data.data.valueRoadMapId;
        if (!this.isDefaultTypeEmpty(focusAreaEfforts,otherAreaEfforts)){
          this.focusAreaEfforts = focusAreaEfforts;
          this.otherAreaEfforts = otherAreaEfforts;
        } else {
          for (let index = 0; index < this.defaultEffortSets.length; index++) {
            let defaultSet = this.defaultEffortSets[index];
            if (!this.isDefaultTypeEmpty(defaultSet.focusAreaEfforts,defaultSet.otherAreaEfforts)){
              this.switchToDefault(defaultSet.name);
              break;
            }
          }
          
        }
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    isDefaultTypeEmpty: function(focusAreaEfforts, otherAreaEfforts){
      if (typeof focusAreaEfforts == "undefined" || typeof otherAreaEfforts == "undefined"){
        return true;
      }
      return focusAreaEfforts.length == 0 && otherAreaEfforts.length == 0 ;
    },
    setEffortSet: function(focusAreaEfforts, otherAreaEfforts){
      this.focusAreaEfforts = focusAreaEfforts;
      this.otherAreaEfforts = otherAreaEfforts;
    }    
  },
  created() {},
  mounted() {
    this.loadData();
  },
  watch: {
    clientChartId: function() {
      this.loadData();
    },
    surveyCode: function() {
      this.loadData();
    }
  },
  computed: {
    clientChartId: function() {
      return this.clientChartObject.clientChartId;
    }
  }
};
</script>
