<template>
  <vue-modal :key="refreshModal" :ref="modalId" :name="modalId" :clickToClose="false" :draggable="true" :adaptive="true" @opened="loadData" :resizable="true" :scrollable="true" :width="modalWidth" :maxHeight="1550" :height="'auto'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="close" @click="closeModal">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="dashboard-modal-card modal-body mx-auto d-flex">
        
        <dashboard-value-road-map ref="valueRoadMap" 
          dashboard-id="valueRoadMap" 
          :entry-survey-code="getCriteriaParameterForDashboardCard(saveClientChartObject,'surveyCode')" 
          :entry-client-chart="saveClientChartObject"
          :input-metric-options="metric_options"
          :read-only="true"
          :input-client-charts="clientObject.clientCharts"
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'value-road-map',false)"
          :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
          :load-competitor-data="true"
        />   
<!--
        <dashboard-moneyball-stick v-if="saveClientChartObject.displayType == 'dashboard-moneyball-stick'"  
          ref="moneyballStick" dashboard-id="moneyballStick" 
          :load-competitor-data="true"
          :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
          :entry-client-chart="saveClientChartObject" 
          :input-client-time-period-options="client_time_periods"
          :read-only="true"
          :entry-survey-code="getCriteriaParameterForDashboardCard(saveClientChartObject,'surveyCode')" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'moat-comps',false)"
          :input-survey-group-id="getCriteriaParameterForDashboardCard(saveClientChartObject,'dashboardSurveyGroupId')"
          :input-use-equal-weight="getCriteriaParameterForDashboardCard(saveClientChartObject,'dashboardUseEqualWeight')"
        />
-->
      </div> 
      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-4" @click="closeModal">Close</button>
      </div>
    </div>
  </vue-modal>
</template>
<script>
import DashboardValueRoadMap from "../components/dashboard/DashboardValueRoadMap.vue";
import DashboardCardMixins from "../mixins/DashboardCardMixins"

import ClientService from "../services/client.service";
import AdminService from "../services/admin.service";
import MetricsService from "../services/metrics.service";
import SurveyGroupService from "../services/survey.group.service";
import SurveyService from "../services/survey.service";
import ClientTimePeriodService from "../services/client.time.period.service";

export default {
  name: "DashboardValueRoadMapModal",
  mixins: [DashboardCardMixins],
  components: {DashboardValueRoadMap},
  props: {
    inputClientTimePeriodOptions: {
      type: Array,
      default: function() {
        return [];
      }
    },    
  },
  data() {
    return {
      title: "Road Map",
      entrySurveyCode: "",
      metric_options: [],
      client_time_periods: []
    };
  },
  methods: {
    loadData: async function(){
      if (this.triggerLoadData == true){
        await this.setupDashboardCard();
        let clientCode = this.getCriteriaParameterForDashboardCard(this.saveClientChartObject,'clientCode');
        this.entrySurveyCode = SurveyService.getSurveyCodeBySurveyId(this.saveClientChartObject.surveyId);
        await ClientTimePeriodService.getClientTimePeriodsBySurveyCode(this.entrySurveyCode, this.getClientTimePeriodsBySurveyCodeCallback, this);
        await MetricsService.getMetricGroupBySurveyCode(this.entrySurveyCode, this.getMetricGroupByClientIdCallback, this);
        this.$refs.valueRoadMap.initialLoadData();
      }
    },
    getClientTimePeriodsBySurveyCodeCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.client_time_periods = response.data.data.clientTimePeriods;
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    getMetricGroupByClientIdCallback(response) {
      this.metric_options = [];
      this.metricId = "";
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        MetricsService.setMetricDropdownOptions(response.data.data,this);
      } else if (statusCode == 201) {
        //        AdminService.displaySuccessMessage({ text: response.data.message, duration: -1 });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    }             
  },
  watch: {},
  created() {},
  mounted()  {},
  umnounted() {},
  computed: {
    triggerLoadData: function() {
      return this.saveClientChartObject != null && this.saveClientChartObject.displayType == "dashboard-value-road-map";
    }    
  }
};
</script>
