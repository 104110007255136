<template>
  <div>
    <div id="chart-container" class="by-value-roadmap">
      <div v-show="this.$parent.getDisplayView() && getDisplayView()" id="cp-by-value-roadmap-e-chart" class="chart-row">
        <profile-chart-header
            :title="'Road Map'"
            :display="this.$parent.getDisplayView() && getDisplayView()"
            :input-display-number-of-responses="false"
            :display-show-respondents="false"            
            @set-display-number-of-responses="setDisplayNumberOfResponses"
        /> 
        <div class="value-road-map-chart-display">
          <div class="buttons-and-chart d-flex">
            <chart-buttons
                  :display="true"
                  :snap-label="'Snap'"
                  :include-advisor-button="$parent.clientChart.clientChartId != 0"           
                  :tooltip-configuration="snapChartTooltipConfig"
                  snap-container-id="value-road-map-display"
                  container-id="value-road-map-display"
                  :report-page-type-name="'Chart - Value Map'"
                  insight-modal-id="profile-by-value-roadmap-chart"
                >
            </chart-buttons>
          </div>
          <div id="value-road-map-display">
                <div class="m-3">
                  <value-road-map v-show="this.noData == false"
                  :value-road-map-data="valueRoadMapData"
                  />
                  <div v-show="this.noData == true" class="no-data-for-chart mx-auto">
                    <div class="pb-2">Not enough data in your selection</div>
                    <div class="pb-2">exists to build a digital profile.</div>
                    <div class="pb-2">Try selecting a different set of chart criteria</div>
                    <div class="pb-2">or areas.</div>
                  </div>                
                </div>
          </div>
        </div>          
      </div>      
      <div v-show="this.$parent.getDisplayView() && getDisplayView()" :id="focusAreasTable.tableContainerId" :class="isInsiderUser ? 'chart-data hide-table-data' : 'chart-data'">
        <div v-show="isInsiderUser" class="overlay">
          <img class="overlay-inner" v-show="isInsiderUser" src="../../../assets/img/overlap.png" style="width: 50%"/>
        </div>
        <chart-data-table
          ref="focusAreasDataTable"
          v-show="this.$parent.getDisplayView() && getDisplayView()"
          :chart-data="this.focusAreasTable.data"
          :display-direction="'vertical'"
          :data-container-id="focusAreasTable.tableContainerId"
          :container-id="'cp-by-value-roadmap-chart'"
          :is-saved-card="$parent.clientChart.clientChartId != 0"           
          :content-list="this.pageContentForValueRoadMap"
          :column-defs="this.chart.columnDefs"
          :table-class="'table display row-border table-hover'"
          :table-width="'auto'"
          :report-page-type-name="'Table - Value Map'"
          :insight-modal-id="'profile-by-value-roadmap-table'"
          :table-title="this.focusAreasTable.title"
          :inputDownloadFileName="'Road Map'"
        ></chart-data-table>
      </div>
    </div>
    <div v-for="(pageContent, idxPageContent) in pageContentForValueRoadMap" v-bind:key="idxPageContent">
      <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
    </div>
    
    <insights
      ref="insightsChart"
      :modal-name="'profile-by-value-roadmap-chart'"
      :report-page-type-name="'Chart - Value Map'"
      :entry-page-content="this.pageContentForReport"
      @saveReportPage="$parent.updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="$parent.saveClientChart"
    />
    <insights
      ref="insightsTable"
      :modal-name="'profile-by-value-roadmap-table'"
      :report-page-type-name="'Table - Value Map'"
      :entry-page-content="this.pageContentForReport"
      @saveReportPage="$parent.updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="$parent.saveClientChart"
    />
  </div>
</template>
<script>
import $ from "jquery";
import ProfileChartHeader from "../../../components/echarts/ProfileChartHeader.vue";
import ValueRoadMap from "../../../components/chart/ValueRoadMap.vue";
import ChartButtons from "../../../components/chart/ChartButtons.vue";
import ChartCompanyMixins from "../../../mixins/ChartCompanyMixins.vue";
import ChartService from "../../../services/chart.service";
import ErrorService from "../../../services/error.service";
import AdminService from "../../../services/admin.service";
import ReportService from "../../../services/report.service";
import ContentService from "../../../services/content.service";
import NeedsService from "../../../services/needs.service";

export default {
  name: "ByValueRoadmap",
  mixins: [ChartCompanyMixins],
  components: { ProfileChartHeader,ChartButtons,ValueRoadMap},
  data() {
    return {
      displayType: "value-roadmap",
      noData: true,
      pageContentForValueRoadMap: [],
      valueRoadMapData: {
        metricData: {
          name: "",
          value: 0,
          metricDirection: ""
        },        
      },
      focusAreasTable: {
        id: "",
        data: {},
        title: '<div class="title"><h4 class="text-primary">Road Map</h4></div>',
        tableContainerId: "value_roadmap_focus_areas_data_container",
      },
      isMobile: false
    };
  },
  props: {
    chartData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    contentList: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    surveyCode: function() {
      return this.$parent.$data.survey.surveyCode;
    },    

  },
  mounted() {},
  created() {
        ContentService.getPageContent("Value Roadmap", this.getPageContentByMoatsCallBack, this);
  },
  methods: {
    getPageContentByMoatsCallBack(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.pageContentForValueRoadMap = response.data.data;
      }
    },
    displayInsights(insightModalId, reportPageTypeName) {
      if (reportPageTypeName == "Chart - Value Map") {
        this.$refs.insightsChart.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.$parent.reportPagesList);
        this.$refs.insightsChart.initializeInsights(this.$parent.clientChart);
        this.$modal.show(insightModalId);
      } else if (reportPageTypeName == "Table - Value Map") {
        this.$refs.insightsTable.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.$parent.reportPagesList);
        this.$refs.insightsTable.initializeInsights(this.$parent.clientChart);
        this.$modal.show(insightModalId);
      }
    },
    async processChartData() {
      try {
        this.$parent.pageContent.push(...this.contentList);
        this.pageContentForValueRoadMap.push(...this.contentList);

        this.chart = ChartService.getChartAttributes("Profile By Value Roadmap");
        let needsData = JSON.parse(JSON.stringify(this.chartData[0]));        
        let otherData = needsData["otherData"];
        let valueRoadmapTableResults = otherData["valueRoadmapTableResults"];

        this.valueRoadMapData = JSON.parse(JSON.stringify(valueRoadmapTableResults));
        this.valueRoadMapData.metricData = {};
        let containerId = needsData["containerId"];
        this.table.id = containerId;
        let copyOfChartData = JSON.parse(JSON.stringify(needsData));
        this.focusAreasTable.id = containerId;
        this.focusAreasTable.data = copyOfChartData;

        let needsList = otherData["needsList"];
        NeedsService.setFunctionalNeedsList(needsList);
        let valueRoadMapId = otherData["valueRoadMapId"];
        this.$emit("set-value-roadmap-id", valueRoadMapId);
        if (typeof this.valueRoadMapData.initiatives != "undefined"){
          this.$emit("set-initiatives", this.valueRoadMapData.initiatives);
        }
        
        this.noData = valueRoadmapTableResults["noData"];
        this.$parent.$data.criteriaResultsList = AdminService.removeByName(needsData.criteriaList, "Competitor");
        this.buildClientChart();
        this.setDisplayView(true);
        this.$parent.setDisplayView(true);

        const vm = this;
        setTimeout(function () {
            vm.saveReportPage();
            vm.saveProfileDataTable();
        }, 1500);

      } catch (err) {
        ErrorService.processException("ByValueRoadmap.displayChartCallback", err, "chart", this);
      }
    },
    async saveProfileDataTable(){
      if (this.$parent.clientChart.clientChartId != 0 && this.isGenerateReportRunning()) {
        let vm = this;
        setTimeout(function() {
          ReportService.saveReportTableFile(vm.$refs.focusAreasDataTable.getTableData(), "Table - Value Map", vm.$parent.clientChart, vm.saveReportTableFileCallback,vm);
          ReportService.saveReportPage(vm.getReportTableContent("cp-by-value-roadmap-chart"),"Table - Value Map", vm.$parent.clientChart, vm.saveReportPageCallback, vm);
        }, 1200);        
      }
    },
    saveAllReportPages() {
        this.saveReportPage();
        this.saveProfileDataTable();
    },
    async saveReportPage() {
      if (this.$parent.clientChart.clientChartId != 0 && this.isGenerateReportRunning()) {
        let vm = this;
        setTimeout(async function () {
          let reportPageChart = $("#value-road-map-display");
          reportPageChart.addClass("word-cloud-display-container");
          let reportPageChartObject = reportPageChart[0];
          let imageReportPageChartContent = await vm.createChartImage(reportPageChartObject, false, true, 4,"pdf-content-image-word-cloud-width");
          await ReportService.saveReportPage(imageReportPageChartContent, "Chart - Value Map", vm.$parent.clientChart, vm.saveReportPageCallback, vm);
          await ReportService.saveReportPageFile(vm.imageUrl, "png", "Chart - Value Map", vm.$parent.clientChart, vm.saveReportPageCallback, vm);
        }, 1200);
      }
    },

  },
  watch: {
    surveyCode: function() {
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      try{this.$refs.eChart.clear();} catch(e){}
    },
    chartData: function() {
      if (this.$parent.isByValueRoadmap) {
        this.processChartData();
      } else {
        this.setDisplayView(false);
      }
    }
  }
};
</script>
