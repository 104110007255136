<template>
  <div v-if="isAdmin" class="playground panel-admin-body">
    <div class="title d-none">
      <div class="d-flex">
        {{ title }}
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-4"> 
         <div class="d-flex">
          <button type="button" class="btn btn-primary ml-2" @click="downloadContactDataByDate">Download Respondent Data</button>
         </div>  
      </div>
    </div>
  </div>
  <div v-else class="panel-admin-body no-permission-list">You do not have permission to view this page</div>
</template>
<script>
import ViewMixins from "../mixins/ViewMixins.vue";
import AdminMixins from "../mixins/AdminMixins.vue";
import DataTableMixins from "../mixins/DataTableMixins.vue";

import AdminService from "../services/admin.service";
import SurveyService from "../services/survey.service";
import ErrorService from "../services/error.service";
import SurveySummaryService from "../services/survey.summary.service";

import $ from "jquery";

export default {
  name: "Playground",
  mixins: [AdminMixins, ViewMixins, DataTableMixins],
  components: {},
  props: {},
  data() {
    return {
      startDate: new Date(2014, 1, 1),
      endDate: new Date(),
      includePartialResponses: true,
      processingComplete: false,
      surveyId: SurveyService.getSurveyId(),
      title: "At the Playground",
    };
  },
  methods: {
    async downloadContactDataByDate() {
      this.processingComplete = false;
      this.setupDownloadItem();
      const timer = setTimeout(() => {
        if (this.processingComplete == false || this.processingComplete == "false") {
          this.processingComplete = true;
          AdminService.displayInfoMessage({ text: "Processing is taking more than usual. The file will be sent via email.", duration: -1 });
		      this.setModalLoading(false);
          this.$root.setLoading(false);
        }
      }, 60000);
      await SurveySummaryService.downloadContactDataByDate(this.startDate, this.endDate, this.includePartialResponses, this.downloadContactDataByDateCallback, this);
      clearTimeout(timer);
    },
    downloadItemError(error) {
      AdminService.displayErrorMessage({ text: "Error Downloading the file" });
      ErrorService.processError("DownloadSurveyData.downloadItem", error, "survey-status", this);
      this.$gtag.exception("Error Downloading the file");
    },
    downloadContactDataByDateCallback(response) {
      // If processing time is less than a 1 minute, file will be downloaded. 
      // Else it'll be sent through email from server side.
      if (this.processingComplete == false || this.processingComplete == "false") {
        this.processingComplete = true;
        this.downloadItemCallback(response);
      }
    },
    getDownloadFilename(){
      return "Respondent Data - " + this.startDate.toLocaleDateString("en-US") + " - " + this.endDate.toLocaleDateString("en-US");
    },   
  },
  created() {},
  mounted() {}
};
</script>
<style lang="scss">
</style>
