<template>
  <div class="saved-reports" style="max-width: 100%; min-height: 150px;">
    <div class="save-chart title mt-3">
      <span class="align-middle pl-2">Respondents</span>
      <div class="float-right ml-auto">
        <span class="wizard-icon">
          <i class="fa-duotone fa-hat-wizard" aria-hidden="true" @click="$root.toggleWizardContent()"></i>
        </span>
        <i v-show="!isDashboardView && this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()" class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        <i v-show="!isDashboardView && this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()" class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
      </div>
    </div>
    <chart-menu></chart-menu>
    <div class="mx-auto" style="width: 95%">
      <div v-show="$root.getShowWizardContent()" class="wizard-content">
        <div class="wizard-content-column wizard-border col-4">
          <div class="circle">1</div>
          <div class="wizard-text">
            Select the Start Date and End Date.
          </div>
        </div>
        <div class="wizard-content-column wizard-border col-4">
          <div class="circle">2</div>
          <div class="wizard-text">
            Click on the button Download Respondent Data
            to download the contact data and any segment information
            of the respondents.

          </div>
        </div>
        <div class="wizard-content-column col-4">
          <div class="circle">3</div>
          <div class="wizard-text">
            Select the checkbox Include Partial Responses
            to filter the data.
          </div>
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div class="d-flex">
          <div class="form-group form-inline col-2">
            <label for="startDate" class="mr-1">Start Date</label>
            <datepicker format="MMM dd yyyy" v-model="startDate" :typeable="true" wrapper-class="" input-class="form-control required w-100 bg-white" name="startDate"></datepicker>
          </div>
          <div class="form-group form-inline ml-2 col-2">
            <label for="endDate" class="mr-1">End Date</label>
            <datepicker format="MMM dd yyyy" v-model="endDate" :typeable="true" wrapper-class="" input-class="form-control required w-100 bg-white" name="endDate"></datepicker>
          </div>
          <div class="form-group form-inline ml-0 mb-0 pr-0 col-3">
            <input
              id="includePartialResponses"
              name="includePartialResponses"
              type="checkbox"
              true-value="Y"
              false-value="N"
              v-model="includePartialResponses"
              @click="saveCheckboxValue($event, 'Include Partial Responses', 'include_partial_responses', true)"
            />
            <span class="ml-1 mr-1">Include Partial Responses</span>
            <modal-link modal-id="Include Partial Responses" :content-list="pageContent"></modal-link>
          </div>
          <div class="pr-2 ml-0" style="padding-top: 15px;">
            <button type="button" class="btn btn-primary ml-0" id="DownloadRespondentData" @click="downloadContactDataByDate">
              Download Respondent Data
              <span class="ml-2">
                <font-awesome-icon :icon="['fa', 'download']" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import ErrorService  from "../services/error.service";
import AdminService from "../services/admin.service";
import ContentService from "../services/content.service";
import ViewMixins from "../mixins/ViewMixins";
import GlobalMixins from "../mixins/GlobalMixins";
import DataTableMixins from "../mixins/DataTableMixins";
import ChartMenu from "../views/menu/ChartMenu.vue";
import Datepicker from "vuejs-datepicker";
import ReportService from "../services/report.service";
import SurveyService from "../services/survey.service";
import SurveySummaryService from "../services/survey.summary.service";
import {
    useRouter
} from 'vue-router';

export default {
  name: "RespondentReport",
  mixins: [ViewMixins, GlobalMixins,DataTableMixins],
  components: {
    ChartMenu,
    Datepicker
  },
  data() {
    return {  
      pageContent: [],
      startDate: new Date(2014, 1, 1),
      endDate: new Date(),
      includePartialResponses: "N"
    };
  },
  props: {},
  computed: {},
  mounted() {
    ContentService.getPageContent("Chart", this.getPageContentByReportCallBack, this);
  },
  created() {},
  methods: {
    getPageContentByReportCallBack(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.pageContent = response.data.data;
      }
    },
    async setupDownloadRespondentDataReport(setModalLoading = true) {
      this.$root.processServerRequest();
      if (setModalLoading == true){
        this.setModalLoading(true);
      }
      AdminService.displayInfoMessage({
        text: "Please stand by as we generate your file….Once complete, the file will appear in your Downloads folder.",
        duration: -1
      })
    },    
    async downloadContactDataByDate() {
      this.processingComplete = false;
      this.setupDownloadItem();
      const timer = setTimeout(() => {
        if (this.processingComplete == false || this.processingComplete == "false") {
          this.processingComplete = true;
          AdminService.displayInfoMessage({ text: "Processing is taking more than usual. The file will be sent via email.", duration: -1 });
		      this.setModalLoading(false);
          this.$root.setLoading(false);
        }
      }, 60000);
      await SurveySummaryService.downloadContactDataByDate(this.startDate, this.endDate, this.includePartialResponses, this.downloadContactDataByDateCallback, this);
      clearTimeout(timer);
    },
    downloadItemError(error) {
      AdminService.displayErrorMessage({ text: "Error Downloading the file" });
      ErrorService.processError("DownloadSurveyData.downloadItem", error, "survey-status", this);
      this.$gtag.exception("Error Downloading the file");
    },
    downloadContactDataByDateCallback(response) {
      // If processing time is less than a 1 minute, file will be downloaded. 
      // Else it'll be sent through email from server side.
      if (this.processingComplete == false || this.processingComplete == "false") {
        this.processingComplete = true;
        this.downloadItemCallback(response);
      }
    },
    getDownloadFilename(){
      return "Respondent Data - " + this.startDate.toLocaleDateString("en-US") + " - " + this.endDate.toLocaleDateString("en-US");
    }, 
    saveCheckboxValue: async function (event) {
      this.includePartialResponses = event.target.checked ? "Y" : "N";
    }
  }
};
</script>
