<template>
    <div class="value-road-map">
        <div class="value-road-map-section wtp">
        <div class="value-road-map-label">Willingness to Pay</div>
        <div class="value-road-map-divider">
            <div class="result-line-up"></div>
        </div>
        <div class="value-road-map-values align-self-center">
            <div class="value-road-map-value-container ">
            <div class="value-road-map-value">
                <div class="value">{{willingnessToPay}}%</div>
                <div class="label">WTP</div>
            </div>
            <div class="value-direction">
                <div :class="getDirectionClass(willingnessToPayDirection,'wtp')"></div>
            </div>
            </div>
        </div>
        <div class="value-road-map-results">
            <div class="focus-area">
                <div v-for="(focusArea, idxFocusArea) in focusAreas" v-bind:key="idxFocusArea">
                  {{ focusArea }}
                </div>
            </div>
        </div>
        </div>
        <div class="value-road-map-section moat-barriers">
        <div class="value-road-map-label">Moat Barriers</div>
        <div class="value-road-map-divider">
            <div class="moat-barriers-divider"></div>
        </div>
        <div class="value-road-map-values">
            <div class="value-road-map-value-container">                    
                <div class="value-road-map-value">
                <div class="value">{{ satGapSize }}</div>
                <div class="label">Sat Gap Size</div>
                </div>
                <div class="value-direction">
                <div :class="getDirectionClass(satGapSizeDirection,'sat')"></div>
                </div>
            </div>
            <div class="value-road-map-value-container">                    
                <div class="value-road-map-value">
                <div class="value"> {{ emotGapSize }}</div>
                <div class="label">Emot Gap Size</div>
                </div>
                <div class="value-direction">
                <div :class="getDirectionClass(emotGapSizeDirection,'emot')"></div>
                </div>
            </div>
        </div>
        <div class="value-road-map-results">
            <div class="moats">
                <div v-for="(moatBarrier, idxMoatBarriers) in moatBarriers" v-bind:key="idxMoatBarriers">
                  {{ moatBarrier }}
                </div>
            </div>
        </div>
        </div>
        <div class="value-road-map-section wts">
        <div class="value-road-map-label">Willingness to Sell</div>
        <div class="value-road-map-divider">
            <div class="result-line-down"></div>
        </div>
        <div v-if="displayMetricData" class="value-road-map-values align-self-center">
            <div class="value-road-map-value-container">
            <div class="value-road-map-value">
                <div class="value">{{ metricData.value }}</div>
                <div class="label">{{ metricData.name }}</div>
            </div>
            <div class="value-direction">
                <div :class="getDirectionClass(metricDirection)"></div>
            </div>
            </div>
        </div>
        <div v-else class="value-road-map-values align-self-center"></div>
        <div class="value-road-map-results">
            <div class="initiatives">
                <div v-for="(initiative, idxInitiatives) in initiatives" v-bind:key="idxInitiatives">
                  {{ initiative.initiative }}
                </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import ContentMixins from "../../mixins/ContentMixins.vue";
import $ from "jquery";

export default {
  name: "ValueRoadMap",
  mixins: [ContentMixins],
  components: {},
  data() {
    return {
      focusAreas: [],
      moatBarriers: [],
      initiatives: [],
      metricData: {
        name: "",
        value: 0,
        metricDirection: "down" 
      },
      willingnessToPay: 0,
      satGapSize: 0,
      emotGapSize: 0,   
      willingnessToPayDirection: "down",
      satGapSizeDirection: "down",    
      emotGapSizeDirection: "down",
      metricDirection: "down"  
    };
  },
  props: {
    valueRoadMapData: {
      type: Object,
      default: function() {
        return {
          metricData: this.metricData,
        };
      }
    },
  },
  computed: {
    displayMetricData: function(){
      return typeof this.metricData != "undefined" && !$.isEmptyObject(this.metricData);
    }
  },
  mounted() {
    this.loadData();
  },
  created() {},
  methods: {
    loadData: function(){
      this.focusAreas = this.valueRoadMapData.focusAreas;
      this.moatBarriers = this.valueRoadMapData.moatBarriers;
      this.initiatives = this.valueRoadMapData.initiatives;
      this.metricData = this.valueRoadMapData.metricData;
      this.willingnessToPay = this.valueRoadMapData.willingnessToPay;
      this.satGapSize = this.valueRoadMapData.satGapSize;
      this.emotGapSize = this.valueRoadMapData.emotGapSize;
      this.willingnessToPayDirection = this.valueRoadMapData.willingnessToPayDirection;
      this.satGapSizeDirection = this.valueRoadMapData.satGapSizeDirection;    
      this.emotGapSizeDirection = this.valueRoadMapData.emotGapSizeDirection;
      this.metricDirection = this.metricData.metricDirection;        
    },
    getDirectionClass: function(direction,type){
      //arrow-head-green-down
      if (type == "wtp"){
        return direction == "up" ? "arrow-head-green" : "arrow-head-red";
      } else if (type == "sat"){
        return direction == "down" ? "arrow-head-green-down" : "arrow-head-red-up";
      } else if (type == "emot"){
        return direction == "down" ? "arrow-head-green-down" : "arrow-head-red-up";
      }
      
    }
  },
  watch: {
    valueRoadMapData: function(){
      this.loadData();
    }
  }
};
</script>
