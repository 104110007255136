import $ from "jquery";
import axios from "axios";
import authHeader from "../auth-header";
import ErrorService from "../error.service";
import AdminService from "../admin.service";
import UserService from "../user.service";
import TableService from "../table.service";
import NeedsService from "../needs.service";
import ChartService from "../chart.service";

const BASE_URL = AdminService.getBaseUrl();
const API_URL = BASE_URL + "/api/v1/chart";

class ValueGroupMap {
    async saveValueRoadMapSetup(surveyCode, focusAreaEfforts, callback, panelApp) {
        const params = new URLSearchParams();
        params.append("surveyCode", surveyCode);
        params.append("focusAreaEfforts", JSON.stringify(focusAreaEfforts));
        params.append("userId", UserService.getUserId());
        await axios
          .post(API_URL + "/save-value-road-map-setup", params, { headers: await authHeader() })
          .then(response => callback(response))
          .catch(error => {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("Winners.saveValueRoadMapSetup", error, "survey-designer", panelApp);
          });
    }    
    async getValueRoadMapSetupBySurveyCode(surveyCode, clientChartId, callback, panelApp) {
        const params = new URLSearchParams();
        params.append("surveyCode", surveyCode);
        params.append("clientChartId", clientChartId);
        params.append("userId", UserService.getUserId());
        await axios
          .post(API_URL + "/get-value-road-map-setup-map-by-survey-code", params, { headers: await authHeader() })
          .then(response => callback(response))
          .catch(error => {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("ValueGroupMap.getValueRoadMapSetupBySurveyCode", error, "survey-designer", panelApp);
          });
      }    
}
export default new ValueGroupMap();
