<template>
  <div>
    <div class="dashboard-period-container pt-2 pb-2">
      <div class="dashboard-period">
        <div class="dashboard-column d-flex pl-0">
          <div class="form-group form-inline mb-0">        
            <div class="control-label">Period</div>
            <div class="pl-1">
              <select id="dashboardPeriod" name="dashboardPeriod" class="custom-select required" :disabled="disableDashboardCriteriaWidgets()" v-model="currentTimePeriod" @change="logChangeEvent()">
                <option value="">Choose A Time Period</option>
                <option v-for="(option, idxPeriodOptions) in client_time_periods" v-bind:value="String(option.clientTimePeriodId)" v-bind:key="idxPeriodOptions">
                  {{ option.chartDisplayName }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group form-inline mb-0 pl-4">
            <div class="control-label ">Trends Start Period</div>
            <div class="pl-1">
              <select id="dashboardPeriod" name="dashboardPeriod" class="custom-select required" :disabled="disableDashboardCriteriaWidgets()" v-model="startTimePeriod" @change="logChangeEventForStartTimePeriod()">
                <option value="">Choose A Time Period</option>
                <option v-for="(option, idxPeriodOptions) in client_time_periods" v-bind:value="String(option.clientTimePeriodId)" v-bind:key="idxPeriodOptions">
                  {{ option.chartDisplayName }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="dashboard-column"></div>        
     <div class="dashboard-column d-flex" >
          <div class="form-group form-inline mb-0">
            <div class="control-label ">Survey Group</div>
            <div class="pl-1">
              <select id="dashboardSurveyGroup" name="dashboardSurveyGroup" class="custom-select required" :disabled="disableDashboardCriteriaWidgets()" v-model="dashboardSurveyGroupId" @change="logChangeEventForDashboardSurveyGroup()">
                <option value="">None</option>
                <option v-for="(option, idxSurveyGroupOptions) in survey_group_options" v-bind:value="String(option.value)" v-bind:key="idxSurveyGroupOptions">
                  {{ option.name }}
                </option>
              </select>
            </div>
            <div class="form-inline pl-4">
              <input 
                id="useEqualWeight" 
                name="useEqualWeight" 
                type="checkbox" 
                true-value="Y" 
                false-value="N"
                ref="dashboardUseEqualWeightCheckbox"
                :disabled="disableUseEqualWeight || disableDashboardCriteriaWidgets()"
                v-model="dashboardUseEqualWeight" 
                @click="logChangeEventForDashboardUseEqualWeight($event)"
                />
              <span class="ml-1 mr-1">Calculate Surveys Equal-Weighted</span>
              <modal-link modal-id="Calculate Surveys Equal-Weighted" :content-list="pageContent"></modal-link>
            </div>
            <div class="float-right pt-1 pl-4" @click="goToDashboardLayout">
                <i v-tooltip="tooltipConfig" class="fa-duotone fa-gear-complex" style="cursor: pointer;"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="displayCards" class="dashboard-card-container">
        <dashboard-respondents ref="respondents" dashboard-id="respondents" :time-period-id="currentTimePeriod" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'respondents-map',false)"
          :load-content="false"
          :entry-survey-code="entrySurveyCode" 
          :initial-page-content-for-report="pageContentForReport"
          :input-survey-group-id="dashboardSurveyGroupId"
          :input-use-equal-weight="dashboardUseEqualWeight"
          @loaded="this.loadedChartRespondents = $event"
          v-if="displayDashboardCard(dashboardLayoutCards,'respondents-map',false)"
        />
        <dashboard-expectations-map ref="expectationsMap" dashboard-id="expectationsMap" :time-period-id="currentTimePeriod" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'expectations-map',false)"
          :load-content="false"
          :initial-page-content-for-report="pageContentForReport"
          v-if="displayDashboardCard(dashboardLayoutCards,'expectations-map',false)"
        />
        <dashboard-performance-trends ref="performanceTrends" dashboard-id="performanceTrends" 
          :time-period-id="currentTimePeriod" 
          :input-start-time-period-id="startTimePeriod"
          :entry-survey-code="entrySurveyCode" 
          :input-metric-id="metricId" 
          :input-metric-options="metric_options"
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'performance-trends',false)"
          :load-content="false"
          :initial-page-content-for-report="pageContentForReport"
          :input-survey-group-id="dashboardSurveyGroupId"
          :input-use-equal-weight="dashboardUseEqualWeight"
          @loaded="this.loadedChartPerformanceTrends = $event"
          v-if="displayDashboardCard(dashboardLayoutCards,'performance-trends',false)"
        />
        <dashboard-competitive-trends ref="competitiveTrends" dashboard-id="competitiveTrends" :time-period-id="currentTimePeriod" 
          :input-start-time-period-id="startTimePeriod"
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'competitive-trends')"
          :entry-survey-code="entrySurveyCode" 
          :load-content="false"
          :initial-page-content-for-report="pageContentForReport"
          v-if="displayDashboardCard(dashboardLayoutCards,'competitive-trends',false)"
        />
        <dashboard-competitive-rank ref="competitiveRank" dashboard-id="competitiveRank" :time-period-id="currentTimePeriod" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'competitive-rank',false)"
          :entry-survey-code="entrySurveyCode" 
          :load-content="false"
          :initial-page-content-for-report="pageContentForReport"
          v-if="displayDashboardCard(dashboardLayoutCards,'competitive-rank',false)"
        />
        <dashboard-nps-trends ref="npsTrends" dashboard-id="npsTrends" :time-period-id="currentTimePeriod" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'nps-trends',false)"
          :input-start-time-period-id="startTimePeriod"
          :load-content="false"
          :entry-survey-code="entrySurveyCode" 
          :initial-page-content-for-report="pageContentForReport"
          :input-survey-group-id="dashboardSurveyGroupId"
          :input-use-equal-weight="dashboardUseEqualWeight"
          @loaded="this.loadedChartNpsTrends = $event"
          v-if="displayDashboardCard(dashboardLayoutCards,'nps-trends',false)"
        />
        <dashboard-needs-profile ref="needsProfile" dashboard-id="needsProfile" :time-period-id="currentTimePeriod" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'needs-profile',false)"
          :load-content="false"
          :load-competitor-data="false"
          :entry-survey-code="entrySurveyCode" 
          :initial-competitors-data="competitor_options"
          :initial-page-content-for-report="pageContentForReport"
          v-if="displayDashboardCard(dashboardLayoutCards,'needs-profile',false)"
        />
        <dashboard-growth-sequence ref="growthSequence" dashboard-id="growthSequence" :time-period-id="currentTimePeriod" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'growth-sequence',false)"
          :load-content="false"
          :load-competitor-data="false"
          :entry-survey-code="entrySurveyCode" 
          :initial-competitors-data="competitor_options"
          :initial-page-content-for-report="pageContentForReport"
          v-if="displayDashboardCard(dashboardLayoutCards,'growth-sequence',false)"
        />
        <dashboard-winners-map ref="growthSequence" dashboard-id="winnersMap" :time-period-id="currentTimePeriod" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'winners-map',false)"
          :entry-survey-code="entrySurveyCode" 
          :load-content="false"
          :load-competitor-data="false"
          :initial-competitors-data="competitor_options"
          :initial-page-content-for-report="pageContentForReport"
          v-if="displayDashboardCard(dashboardLayoutCards,'winners-map',false)"
        />
        <dashboard-predictive-analytics-contributions ref="predictiveAnalytics" 
            dashboard-id="predictiveAnalytics" 
            :input-metric-options="metric_options"
            :time-period-id="currentTimePeriod" 
            :input-is-pricing-power="true"
            :input-metric-id="metricId" 
            :entry-survey-code="entrySurveyCode" 
            :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'predictive-analytics',false)"
            :load-content="false"
            :initial-page-content-for-report="pageContentForReport"
            :input-survey-group-id="dashboardSurveyGroupId"
            :input-use-equal-weight="dashboardUseEqualWeight"
            @loaded="this.loadedChartPredictiveAnalytics = $event"
            v-if="displayDashboardCard(dashboardLayoutCards,'predictive-analytics',false)"
        />
        <dashboard-predictive-analytics-contributions ref="metricContributions" 
            dashboard-id="metricContributions" 
            :input-metric-options="metric_options"
            :time-period-id="currentTimePeriod" 
            :input-is-pricing-power="false"
            :input-metric-id="metricId" 
            :entry-survey-code="entrySurveyCode" 
            :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'metric-contributions',false)"
            :load-content="false"
            :initial-page-content-for-report="pageContentForReport"
            :input-survey-group-id="dashboardSurveyGroupId"
            :input-use-equal-weight="dashboardUseEqualWeight"
            @loaded="this.loadedChartMetricContributions = $event"
            v-if="displayDashboardCard(dashboardLayoutCards,'metric-contributions',false)"
        />
        <dashboard-welcome :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'welcome',false)"
          :load-content="false"
          :initial-page-content-for-report="pageContentForReport"
          v-if="displayDashboardCard(dashboardLayoutCards,'welcome',false)"
        />
        <dashboard-rank ref="npsRank" 
          dashboard-id="npsRank" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'nps-rank',false)"
          :load-content="false"
          rank-type="nps-rank"
          :entry-survey-code="entrySurveyCode" 
          :time-period-id="currentTimePeriod" 
          :input-group-options="surveyGroupList"
          :initial-page-content-for-report="pageContentForReport"
          v-if="displayDashboardCard(dashboardLayoutCards,'nps-rank',false)"
        />
        <dashboard-rank ref="moatScoreRank"
          dashboard-id="moatScoreRank" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'moat-score-rank',false)"
          :load-content="false"
          rank-type="moat-score-rank"
          :entry-survey-code="entrySurveyCode" 
          :time-period-id="currentTimePeriod" 
          :input-group-options="surveyGroupList"
          :initial-page-content-for-report="pageContentForReport"
          v-if="displayDashboardCard(dashboardLayoutCards,'moat-score-rank',false)"
        />
        <dashboard-rank ref="wScoreRank"
          dashboard-id="wScoreRank" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'w-score-rank',false)"
          :load-content="false"
          rank-type="w-score-rank"
          :entry-survey-code="entrySurveyCode" 
          :input-group-options="surveyGroupList"
          :time-period-id="currentTimePeriod" 
          :initial-page-content-for-report="pageContentForReport"
          v-if="displayDashboardCard(dashboardLayoutCards,'w-score-rank',false)"
        />
        <dashboard-moat-comps ref="moatComps" 
          dashboard-id="moatComps" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'moat-comps',false)"
          :time-period-id="currentTimePeriod" 
          :input-group-options="surveyGroupList"
          :load-competitor-data="true"
          v-if="displayDashboardCard(dashboardLayoutCards,'moat-comps',false)"
        />
        <dashboard-moneyball-stick ref="moneyballStick" 
          dashboard-id="moneyballStick" 
          :input-client-time-period-options="client_time_periods"
          :input-client-charts="this.clientObject.clientCharts"
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'moneyball-stick',false)"
          :time-period-id="currentTimePeriod" 
          :load-competitor-data="true"
          :input-save-settings="true"
          :input-survey-group-id="dashboardSurveyGroupId"
          :input-use-equal-weight="dashboardUseEqualWeight"
          @loaded="this.loadedChartMoneyballStick = $event"
          v-if="displayDashboardCard(dashboardLayoutCards,'moneyball-stick',false)"
        />
        <dashboard-value-road-map ref="valueRoadMap" 
          dashboard-id="valueRoadMap" 
          :input-metric-id="metricId" 
          :input-metric-options="metric_options"
          :input-client-charts="this.clientObject.clientCharts"
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'value-road-map',false)"
          :time-period-id="currentTimePeriod" 
          :load-competitor-data="true"
          v-if="displayDashboardCard(dashboardLayoutCards,'value-road-map',false)"
        />
        <!--
        <dashboard-metric-trends ref="metricTrends" 
          dashboard-id="metricTrends" 
          :input-metric-id="metricId" 
          :input-metric-options="metric_options"
          :input-client-charts="this.clientObject.clientCharts"
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'dashboard-metric-trends',false)"
          :time-period-id="currentTimePeriod" 
          :load-competitor-data="true"
          v-if="displayDashboardCard(dashboardLayoutCards,'dashboard-metric-trends',false)"
        />
        -->
    </div>  
  </div>
</template>

<script>
import ContentService from "../services/content.service";
import ClientCompetitorService  from "../services/client.competitor.service";
import AdminService from "../services/admin.service";
import UserSettingsService from "../services/user.settings.service";
import ClientTimePeriodService from "../services/client.time.period.service";
import GoogleService from "../services/google.service";
import MetricsService from "../services/metrics.service";
import SurveyService from "../services/survey.service";
import UserService from "../services/user.service";
import SurveyGroupService from "../services/survey.group.service";
import DashboardService from "../services/dashboard.service";
import DashboardRespondents from "./dashboard/DashboardRespondents.vue";
import DashboardCompetitiveRank from "./dashboard/DashboardCompetitiveRank.vue";
import DashboardCompetitiveTrends from "./dashboard/DashboardCompetitiveTrends.vue";
import DashboardExpectationsMap from "./dashboard/DashboardExpectationsMap.vue";
import DashboardPerformanceTrends from "./dashboard/DashboardPerformanceTrends.vue";
import DashboardPredictiveAnalyticsContributions from "./dashboard/DashboardPredictiveAnalyticsContributions.vue";
import DashboardNpsTrends from "./dashboard/DashboardNpsTrends.vue";
import DashboardNeedsProfile from "./dashboard/DashboardNeedsProfile.vue";
import DashboardGrowthSequence from "./dashboard/DashboardGrowthSequence.vue";
import DashboardWinnersMap from "./dashboard/DashboardWinnersMap.vue";
import DashboardWelcome from "./dashboard/DashboardWelcome.vue";
import DashboardMoatComps from "./dashboard/DashboardMoatComps.vue";
import DashboardMoneyballStick from "./dashboard/DashboardMoneyballStick.vue";
import DashboardValueRoadMap from "./dashboard/DashboardValueRoadMap.vue";
//import DashboardMetricTrends from "./dashboard/DashboardMetricTrends.vue";

import DashboardRank from "./dashboard/DashboardRank.vue";
import $ from "jquery";

export default {
  name: "DashboardCards",
  mixins: [],
  components: {
    DashboardRespondents,
    DashboardCompetitiveRank,
    DashboardCompetitiveTrends,
    DashboardExpectationsMap,
    DashboardPerformanceTrends,
    DashboardPredictiveAnalyticsContributions,
    DashboardNpsTrends,
    DashboardNeedsProfile,
    DashboardGrowthSequence,
    DashboardWinnersMap,
    DashboardWelcome,
    DashboardMoatComps,
    DashboardMoneyballStick,
    DashboardValueRoadMap,
//    DashboardMetricTrends,
    DashboardRank
  },
  data() {
    return {
      currentTimePeriod: "",
      startTimePeriod: "",
      pageContentForReport: [],
      competitor_options: [],
      client_time_periods: [],
      defaultDashboardValues: {},
      dashboardLayoutCards: [],
      surveyGroupList: [],
      displayCards: false,
      metricId: "",
      metric_options: [],
      tooltipConfig: {
        placement: "top",
        content: "Click to configure the dashboard layout ",
        trigger: "hover",
        delay: { show: 500, hide: 500 }
      },     
      defaultGroup: { dashboardCardId: 0, id: 0, sequenceNumber: 100, dashboardCardTypeCode: "" },
      dashboardSurveyGroupId: "",
      survey_group_options: [],
      dashboardUseEqualWeight: "N",
      disableUseEqualWeight: true,
      loadedChartRespondents: true,
      loadedChartPredictiveAnalytics: true,
      loadedChartMetricContributions: true,
      loadedChartPerformanceTrends: true,
      loadedChartNpsTrends: true,
      loadedChartMoneyballStick: true,
    };
  },
  props: {
    entrySurveyCode: {
      type: String,
      default: ""
    },
    clientObject: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  computed: {},
  mounted() {},
  created() {
    ContentService.getPageContent("Report", this.getPageContentByReportCallBack, this);
    this.loadData();
  },
  methods: {
    getPageContentByReportCallBack(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.pageContentForReport = response.data.data;
      }
    },
    displayDashboardCard: function(layoutCards, code) {
      return DashboardService.displayDashboardCard(layoutCards, code);
    },
    getDashboardLayoutCard: function(layoutCards, code) {
      return DashboardService.getDashboardLayoutCard(layoutCards, code);
    },
    goToDashboardLayout: function() {
      GoogleService.logClickEvent("Dashboard", "Go to Dashboard Layout", 0, this);
      this.$router.replace({ name: "dashboard-layout" });
    },
    saveUserSettingCallback: function (response) {
      UserSettingsService.saveAllUserSettings(response.data.data.userSettings);
    },    
    logChangeEventForStartTimePeriod(){
      if (this.startTimePeriod == "") {
        return;
      }
      let periodName = ClientTimePeriodService.getTimePeriodName(this.client_time_periods, this.startTimePeriod);
      if (periodName != "") {
        UserSettingsService.saveUserSetting("dashboard-start-period", periodName, 0, this.saveUserSettingCallback, this);
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'performance-trends',false)){
        // eslint-disable-next-line no-console
        //console.log("DashboardCards::logChangeEventForStartTimePeriod running performanceTrends.updateCard()");
        this.$refs.performanceTrends.updateCard();
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'nps-trends',false)){
        // eslint-disable-next-line no-console
        //console.log("DashboardCards::logChangeEventForStartTimePeriod running npsTrends.initialLoadData()");
        this.$refs.npsTrends.initialLoadData();
      }
      GoogleService.logActionEvent("change", "Dashboard", "", SurveyService.getSurveyName(), "Start Period", this);
    },   
    logChangeEventForDashboardSurveyGroup: async function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardCards::dashboardSurveyGroupId: " + this.dashboardSurveyGroupId);
      await UserSettingsService.saveUserSetting("dashboard-survey-group", this.dashboardSurveyGroupId, 0, this.saveUserSettingCallback, this);      
      if(this.dashboardSurveyGroupId == "") {
        this.disableUseEqualWeight = true;
        this.dashboardUseEqualWeight = "N";
        await UserSettingsService.saveUserSetting("dashboard-use-equal-weight", this.dashboardUseEqualWeight, 0, this.saveUserSettingCallback, this);      
      } else {
        this.disableUseEqualWeight = false;
      }
      this.refreshSurveyGroupCards();
      GoogleService.logActionEvent("change", "Dashboard", "", SurveyService.getSurveyName(), "Survey Group", this);
    },  
    logChangeEventForDashboardUseEqualWeight: async function (event) {
      this.$refs.dashboardUseEqualWeightCheckbox.disabled=true;
      let checkboxValue = event.target.checked ? "Y" : "N";
      // eslint-disable-next-line no-console
      //console.log("DashboardCards::dashboardUseEqualWeight: " + checkboxValue);
      await UserSettingsService.saveUserSetting("dashboard-use-equal-weight", checkboxValue, 0, this.saveUserSettingCallback, this);      
      this.refreshSurveyGroupCards(true);
      this.$refs.dashboardUseEqualWeightCheckbox.disabled=false;
      GoogleService.logActionEvent("change", "Dashboard", "", SurveyService.getSurveyName(), " Use Equal Weight", this);
    },  
    logChangeEvent(){
      if (this.currentTimePeriod == "") {
        return;
      }
      let periodName = ClientTimePeriodService.getTimePeriodName(this.client_time_periods, this.currentTimePeriod);
      if (periodName != "") {
        UserSettingsService.saveUserSetting("dashboard-period", periodName, 0, this.saveUserSettingCallback, this);
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'predictive-analytics',false)){
        // eslint-disable-next-line no-console
        //console.log("DashboardCards::logChangeEvent running predictiveAnalytics.initialLoadData()");
        this.$refs.predictiveAnalytics.initialLoadData();
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'metric-contributions',false)){
        // eslint-disable-next-line no-console
        //console.log("DashboardCards::logChangeEvent running metricContributions.initialLoadData()");
        this.$refs.metricContributions.initialLoadData();
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'nps-rank',false)){
        // eslint-disable-next-line no-console
        //console.log("DashboardCards::logChangeEvent running npsRank.initialLoadData()");
        this.$refs.npsRank.initialLoadData();
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'w-score-rank',false)){
        // eslint-disable-next-line no-console
        //console.log("DashboardCards::logChangeEvent running wScoreRank.initialLoadData()");
        this.$refs.wScoreRank.initialLoadData();
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'moat-score-rank',false)){
        // eslint-disable-next-line no-console
        //console.log("DashboardCards::logChangeEvent running moatScoreRank.initialLoadData()");
        this.$refs.moatScoreRank.initialLoadData();
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'moneyball-stick',false)){
        // eslint-disable-next-line no-console
        //console.log("DashboardCards::logChangeEvent running moneyballStick.initialLoadData()");
        this.$refs.moneyballStick.initialLoadData();
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'respondents-map',false)){
        // eslint-disable-next-line no-console
        //console.log("DashboardCards::logChangeEvent running moneyballStick.initialLoadData()");
        this.$refs.respondents.updateCard();
      }

      GoogleService.logActionEvent("change", "Dashboard", "", SurveyService.getSurveyName(), "Period", this);
    },   
    logViewDetailsEvent(title){
      GoogleService.logActionEvent("click", "Dashboard", title, SurveyService.getSurveyName(), "View Details", this);
    },
    getClientTimePeriodsBySurveyCodeCallback(response) {
      // eslint-disable-next-line no-console
      //console.log("DashboardCards::getClientTimePeriodsBySurveyCode");
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.defaultDashboardValues = response.data.data.defaultDashboardValues;
        this.client_time_periods = response.data.data.clientTimePeriods;
        if (typeof response.data.data.startTimePeriod != "undefined") {
          this.startTimePeriod = String(response.data.data.startTimePeriod);
        }                
        if (typeof response.data.data.currentTimePeriod != "undefined") {
          this.currentTimePeriod = String(response.data.data.currentTimePeriod);
        }
        if (this.currentTimePeriod == ""){
          AdminService.displayErrorMessage({ text: "Please select a time period from the dropdown. If none exist go to Respondent->Waves to create them." });
        } 
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    getDashboardCardsCallback: function (response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.dashboardLayoutCards = response.data.data;
        let currentSequenceNumber = this.dashboardLayoutCards.length;
        while (this.dashboardLayoutCards.length < 6) {
          let cardToAdd = JSON.parse(JSON.stringify(this.defaultGroup));
          currentSequenceNumber += 1;
          cardToAdd.sequenceNumber = currentSequenceNumber;
          this.dashboardLayoutCards.push(cardToAdd);
        }
        this.displayCards = true;
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    loadData: async function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardCards::loadData");
      this.getDashboardSurveyGroupWithUseEqualWeight();
      await DashboardService.getDashboardCards(UserService.getUserId(),this.getDashboardCardsCallback, this);
      await ClientTimePeriodService.getClientTimePeriodsBySurveyCode(this.entrySurveyCode, this.getClientTimePeriodsBySurveyCodeCallback, this);
      await MetricsService.getMetricGroupBySurveyCode(this.entrySurveyCode, this.getMetricGroupByClientIdCallback, this);
      await ClientCompetitorService.getCompetitors(this.$parent.clientId, this.currentTimePeriod, this.loadCompetitors);
      await SurveyGroupService.getSurveyGroupsByClientId(this.clientObject.clientCode,this.getSurveyGroupsByClientIdCallback, this);
      
      if (this.displayDashboardCard(this.dashboardLayoutCards,'nps-rank',false)){
      // eslint-disable-next-line no-console
      //console.log("DashboardCards::loadData running npsRank.initialLoadData()");
        await this.$refs.npsRank.initialLoadData();
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'moat-score-rank',false)){
      // eslint-disable-next-line no-console
      //console.log("DashboardCards::loadData running moatScoreRank.initialLoadData()");
        await this.$refs.moatScoreRank.initialLoadData();
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'w-score-rank',false)){
      // eslint-disable-next-line no-console
      //console.log("DashboardCards::loadData running wScoreRank.initialLoadData()");
        await this.$refs.wScoreRank.initialLoadData();
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'moat-comps',false)){
      // eslint-disable-next-line no-console
      //console.log("DashboardCards::loadData running moatComps.initialLoadData()");
        await this.$refs.moatComps.initialLoadData();
      }
      this.refreshSurveyGroupCards(true); // DashboardMixins watcher: timePeriodId is already loading respondents, so send true here to avoid muliple calls
    },

    loadCompetitors(data) {
      this.competitor_options = [];
      for (var i = 0; i < data.length; i++) {
        this.competitor_options.push({ value: data[i].companyId, name: data[i].name });
      }
    },
    getSurveyGroupsByClientIdCallback(response) {
      this.surveyGroupList = [];
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        let surveyGroups = response.data.data.surveyGroupList;
        this.surveyGroupList =  SurveyGroupService.getSurveyGroupOptions(surveyGroups,"Y",this.$parent.clientId);
        this.survey_group_options = this.surveyGroupList;
        if(this.dashboardSurveyGroupId != "") {
          let surveyGroupOption = this.survey_group_options.filter(option => option.value == this.dashboardSurveyGroupId);
          if (surveyGroupOption.length > 0) {
            surveyGroupOption.forEach(option => option.checked = true);
            this.setDashboardSurveyGroup(this.dashboardSurveyGroupId);
          } else {
            this.setDashboardSurveyGroup("");
          }
        } else {
          this.setDashboardSurveyGroup("");
        }
      } else if (statusCode == 201) {
        //        AdminService.displaySuccessMessage({ text: response.data.message, duration: -1 });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    getMetricGroupByClientIdCallback(response) {
      this.metric_options = [];
      this.metricId = "";
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        MetricsService.setMetricDropdownOptions(response.data.data,this);
      } else if (statusCode == 201) {
        //        AdminService.displaySuccessMessage({ text: response.data.message, duration: -1 });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    setDashboardSurveyGroup(value) {
      if (typeof value == "undefined" || value == "") {
        value = "";
        this.setDashboardUseEqualWeight("");
      }
      this.dashboardSurveyGroupId = value;
      this.disableUseEqualWeight = (value == "");
      UserSettingsService.saveUserSetting("dashboard-survey-group", value, 0, this.saveUserSettingCallback, this);
    },
    getDashboardSurveyGroupWithUseEqualWeight() {
      let savedDashboardSurveyGroupId = UserSettingsService.getUserSetting("dashboard-survey-group");
      let savedDashboardUseEqualWeight = UserSettingsService.getUserSetting("dashboard-use-equal-weight");
      if(savedDashboardSurveyGroupId == "") {
        this.disableUseEqualWeight = true;
        savedDashboardUseEqualWeight = "N";
      } else {
        this.disableUseEqualWeight = false;
      }
      this.dashboardUseEqualWeight = savedDashboardUseEqualWeight;
      this.dashboardSurveyGroupId = savedDashboardSurveyGroupId;
    },
    setDashboardUseEqualWeight(value) {
      let checkboxValue = (typeof value == "undefined" || value == "" || value == "N") ? "N" : "Y";
      this.dashboardUseEqualWeight = checkboxValue;
      UserSettingsService.saveUserSetting("dashboard-use-equal-weight", checkboxValue, 0, this.saveUserSettingCallback, this);
    },
    refreshSurveyGroupCards: async function(checkboxOnly = false) {
      if (this.currentTimePeriod == "") {
        return;
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'performance-trends',false)){
      // eslint-disable-next-line no-console
      //console.log("DashboardCards::refreshSurveyGroupCards running performanceTrends.initialLoadData()");
        await this.$refs.performanceTrends.initialLoadData();
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'nps-trends',false)){
      // eslint-disable-next-line no-console
      //console.log("DashboardCards::refreshSurveyGroupCards running npsTrends.loadData()");
        await this.$refs.npsTrends.initialLoadData();
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'metric-contributions',false)){
      // eslint-disable-next-line no-console
      //console.log("DashboardCards::refreshSurveyGroupCards running metricContributions.initialLoadData()");
        await this.$refs.metricContributions.initialLoadData();
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'predictive-analytics',false)){
      // eslint-disable-next-line no-console
      //console.log("DashboardCards::refreshSurveyGroupCards running predictiveAnalytics.initialLoadData()");
        await this.$refs.predictiveAnalytics.initialLoadData();
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'moneyball-stick',false)){
      // eslint-disable-next-line no-console
      //console.log("DashboardCards::refreshSurveyGroupCards running moneyballStick.initialLoadData()");
        await this.$refs.moneyballStick.initialLoadData();
      }
      if (checkboxOnly == false){
        if (this.displayDashboardCard(this.dashboardLayoutCards,'respondents-map',false)){
      // eslint-disable-next-line no-console
      //console.log("DashboardCards::refreshSurveyGroupCards running respondents.updateCard()");
          await this.$refs.respondents.updateCard();
        }
      }
    },
    disableDashboardCriteriaWidgets() {
      let pageLoadComplete = this.$root.getLoading() ? false : true;
      let refreshCardsProcessComplete = this.loadedChartRespondents && this.loadedChartPredictiveAnalytics && this.loadedChartNpsTrends
      && this.loadedChartMetricContributions && this.loadedChartPerformanceTrends && this.loadedChartMoneyballStick;
      if(pageLoadComplete && refreshCardsProcessComplete) {
        return false;
      }
      return true;       
    },
  },
  watch: {
    loadedChartRespondents: function() {
      this.disableDashboardCriteriaWidgets();
    },
    loadedChartPredictiveAnalytics: function() {
      this.disableDashboardCriteriaWidgets();
    },
    loadedChartMetricContributions: function() {
      this.disableDashboardCriteriaWidgets();
    },
    loadedChartPerformanceTrends: function() {
      this.disableDashboardCriteriaWidgets();
    },
    loadedChartNpsTrends: function() {
      this.disableDashboardCriteriaWidgets();
    },
    loadedChartMoneyballStick: function() {
      this.disableDashboardCriteriaWidgets();
    },
    entrySurveyCode: async function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardCards::entrySurveyCode");
      this.metricId = "";
      this.currentTimePeriod = "";
      this.startTimePeriod = "";
      this.loadData();
    },
    startTimePeriod: async function(){
      if (this.startTimePeriod == "") {
        return;
      }
      if (this.displayDashboardCard(this.dashboardLayoutCards,'competitive-trends',false)){
      // eslint-disable-next-line no-console
      //console.log("DashboardCards::startTimePeriod running competitiveTrends.initialLoadData()");
        await this.$refs.competitiveTrends.initialLoadData();
      }
    },
    currentTimePeriod: async function() {
      // eslint-disable-next-line no-console
      if (this.currentTimePeriod == "") {
        return;
      }
      // eslint-disable-next-line no-console
      //console.log("DashboardCards::currentTimePeriod value: " + this.currentTimePeriod);
      try {
        await ClientCompetitorService.getCompetitors(this.$parent.clientId, this.currentTimePeriod, JSON.stringify(this.loadCompetitors));
      } catch (e) {
        AdminService.displayErrorMessage("DashboardCards.currentTimePeriod getCompetitors", e, "admin", this);
      }
    },    
    metric: function() {
      //this.$refs.predictiveAnalytics.initTable();
    },
  }
};
</script>
