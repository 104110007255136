<template>
  <div class="w-100">
    <div class="edit-question-container">
      <div class="row m-1">
        <form
          accept-charset="UTF-8"
          enctype="multipart/form-data"
          autocomplete="off"
          class="form w-100 form-row align-items-center p-3"
          style="border-bottom: 1px solid rgb(208, 210, 211)"
          action="#"
          method="post"
          novalidate="novalidate"
          :id="'QuestionFormId_' + sectionId"
        >
          <div class="mr-1">Q{{ newQuestionIndex }}</div>
          <div v-if="selectedWidgetType !== undefined && selectedWidgetType.name == 'Slider'" class="form-inline col-8">
            <div class="form-inline w-30">
              <label for="leftLabel" class="mr-1">Left Label</label>
              <input v-model="newMultiQuestionLabel[0]" :id="newQuestionLabel" :name="newQuestionLabel" type="text" class="form-control w-75 required" />
            </div>
            <div class="form-inline w-30">
              <label for="rightLabel" class="mr-1">Right Label</label>
              <input v-model="newMultiQuestionLabel[1]" :id="newQuestionLabel" :name="newQuestionLabel" type="text" class="form-control w-75 required" />
            </div>
          </div>
          <div v-else class="col-8">
            <input v-model="newQuestionLabel" :id="'newQuestionLabel_' + sectionId" :name="newQuestionLabel" type="text" class="form-control required" style="width: 100%" />
          </div>
          <div class="col-3">
            <select :id="'widgetType_' + sectionId" v-model="newQuestionWidgetTypeId" name="widgetType" class="custom-select" @change="setSelectedWidgetType">
              <option value="">Select</option>
              <option
                v-for="(option, idxWidgetTypeOptionsNewQuestion) in this.widgetTypeOptions"
                v-bind:value="option.value"
                v-bind:key="idxWidgetTypeOptionsNewQuestion"
                v-bind:selected="newQuestionWidgetTypeId == option.value"
              >
                {{ option.name }}
              </option>
            </select>
          </div>
          <div v-if="displayDefaultValue" class="form-row w-100 mt-2">
             <question-default-value :initial-default-value="widgetConfiguration.defaultValue" :display-feature="displayDefaultValue" @save="setWidgetConfigurationDefaultValue"> </question-default-value>
          </div>
          <div v-if="displayPlaceholder" class="form-row w-100 mt-2">
            <div class="form-group col-12">
              <question-placeholder :initial-placeholder="widgetConfiguration.placeholder" :display-feature="displayPlaceholder" @save="setWidgetConfigurationPlaceholder"> </question-placeholder>
            </div>
          </div>
          <div class="segment-question-section">
            <div class="segment-question-sub-section">
              <div v-show="displayRequired" class="">
                  <input
                    type="checkbox"
                    :id="'required_' + sectionId"
                    name="required"
                    v-model="newQuestionRequired"
                    @click="checkQuestionRequired($event)"
                    true-value="Y"
                    false-value="N"
                  />
                  <label for="required" class="control-label pl-1">Answer Required</label>
              </div>
            </div>
            <div class="segment-question-sub-section segment-section-border display-section">
              <div v-show="showDisplayField" class="">
                  <input
                    type="checkbox"
                    :id="'display_' + sectionId"
                    name="display"
                    v-model="newQuestionDisplay"
                    @click="checkQuestionDisplayOnSurvey($event)"
                    true-value="Y"
                    false-value="N"
                  />
                  <label for="display" class="control-label pl-1">Display in Survey</label>
              </div>
              <div v-show="displayNumberOfColumns" class="">
                  <label for="number_of_columns" class="control-label pr-1"># of display columns for options</label>
                  <select id="number_of_columns" v-model="widgetConfiguration.number_of_columns" name="number_of_columns" class="custom-select">
                    <option
                      v-for="(option, idxNumberOfColumnsOptions) in this.number_of_columns_options"
                      v-bind:value="option.value"
                      v-bind:key="idxNumberOfColumnsOptions"
                      v-bind:selected="newQuestionWidgetTypeId == option.value"
                    >
                      {{ option.name }}
                    </option>
                  </select>
              </div>
              <div v-show="showDisplayWideLabel" class="" >
                <input type="checkbox" id="displayWideLabel" name="displayWideLabel" v-model="widgetConfiguration.displayWideLabel" />
                <label for="displayWideLabel" class="control-label pl-1">Display Wide Label</label>
              </div>
            </div>
            <div class="segment-question-sub-section segment-section-border segment-section">
              <div v-show="displayIncludeAsSegment" class="">
                <label for="segment" class="control-label pr-1">Display in Segments</label>
                <input type="checkbox" :id="'segment_' + sectionId" name="segment" v-model="newIncludeAsSegment" true-value="Y" false-value="N" @click="checkSegmentLimit($event)" />
              </div>
              <div v-show="newIncludeAsSegment == 'Y'" class="">
                <label for="segmentLabel" class="control-label pr-1 text-danger">Segment Label</label>
                <input
                  v-model="newSegmentLabel"
                  :id="'segmentLabel_' + sectionId"
                  name="segmentLabel"
                  type="text"
                  :class="newIncludeAsSegment == 'Y' ? 'form-control required' : 'form-control'"
                />
              </div>             
            </div>
            <div class="segment-question-section">
              <div v-show="displayIncludeInExecutiveDownload" class="">
                    <input type="checkbox" id="segment" name="segment" v-model="newIncludeInExecutiveDownload" true-value="Y" false-value="N" />
                    <label for="segment" class="control-label pl-1">Include in Executive Download</label>
              </div>
            </div>
          </div>
        </form>
        <question-type-ahead
          v-if="selectedWidgetType !== undefined && selectedWidgetType.name == 'Type Ahead'"
          :question-id="0"
        ></question-type-ahead>
        <question-content
          v-if="selectedWidgetType !== undefined && selectedWidgetType.name == 'Content Section'"
          :survey-name="$parent.surveyName"
          :initial-content-id="Number(widgetConfiguration.contentId)"
          :content-sections="$parent.surveyContentSections"
          @save="setWidgetConfigurationContentId"
          @reload="$emit('reload')"
        >
        </question-content>
        <question-drag-drop
          v-if="selectedWidgetType !== undefined && selectedWidgetType.name == 'Drag and Drop'"
          :initial-choices="widgetConfiguration.choices"
          :initial-choice-order="widgetConfiguration.choiceOrder"
          :initial-number-of-choices-to-rank="Number(widgetConfiguration.numberOfChoicesToRank)"
          @save="setWidgetConfigurationDragDrop"
        ></question-drag-drop>
        <div v-show="selectedWidgetType !== undefined && selectedWidgetType.name == 'Slider'" class="form-inline p-3 mt-2">
          <div class="form-group w-25">
            <label for="minimumValue" class="mr-1">Minimum Value</label>
            <input v-model="widgetConfiguration.min" :id="'minimumValue_' + sectionId" name="minimumValue" type="text" class="form-control w-30" />
          </div>
          <div class="form-group w-25">
            <label for="minimumValue" class="mr-1">Maximum Value</label>
            <input v-model="widgetConfiguration.max" :id="'maximumValue_' + sectionId" name="maximumValue" type="text" class="form-control w-30" />
          </div>
          <div class="form-group w-25">
            <label for="minimumValue" class="mr-1">Increment Value</label>
            <input v-model="widgetConfiguration.step" :id="'incrementValue_' + sectionId" name="incrementValue" type="text" class="form-control w-30" />
          </div>
          <div class="form-group w-25">
            <label for="minimumValue" class="mr-1">Default Value</label>
            <input v-model="widgetConfiguration.value" :id="'defaultValue_' + sectionId" name="defaultValue" type="text" class="form-control w-30" />
          </div>
        </div>
        <div class="w-100 row p-2 no-gutters">
          <div class="col-4">
              <question-checkbox
              v-if="selectedWidgetType !== undefined && selectedWidgetType.name == 'Checkbox Group'"
              :initial-max-enabled="widgetConfiguration.maximumNumberOfValuesEnabled"
              :initial-max-number="Number(widgetConfiguration.maximumNumberOfValues)"
              :initial-choices="widgetConfiguration.options"
              :initial-choice-order="widgetConfiguration.choiceOrder"
              @saveLimitResponses="setWidgetConfigurationCheckboxLimitResponsesOnly"          
              @saveOrder="setWidgetConfigurationCheckboxOrderOnly"          
              @save="setWidgetConfigurationCheckbox"          
            ></question-checkbox>
          </div>
          <div class="col-8">
            <question-option
              v-for="(widgetConfigurationOption, idxWidgetConfiguration) in this.widgetConfiguration.options"
              class="w-100 form-row"
              :key="widgetConfigurationOption.value"
              :new-question-option-index="idxWidgetConfiguration"
              :option-object="widgetConfigurationOption"
              @save="saveOption"
              @remove="removeOption"
            >
            </question-option>
          </div>
        </div>
        <div class="w-100 form-row">
          <div class="col p-3">
            <button class="btn btn-primary float-right ml-2" :disabled="disableCancelButton" @click="showConfirmationMessage">Cancel</button>
            <button type="submit" class="btn btn-primary float-right ml-2" :disabled="disableSaveButton" @click="addQuestion()">
              <span>Save Question</span>
              <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import jQuery from "jquery";
import $ from "jquery";

import QuestionOption from "../components/QuestionOption.vue";
import QuestionContent from "../components/QuestionContent.vue";
import QuestionPlaceholder from "../components/QuestionPlaceholder.vue";
import QuestionDefaultValue from "../components/QuestionDefaultValue.vue";
import QuestionDragDrop from "../components/QuestionDragDrop.vue";
import QuestionCheckbox from "../components/QuestionCheckbox.vue";
import QuestionTypeAhead from "../components/QuestionTypeAhead.vue";

import AdminService from "../services/admin.service";
import QuestionService from "../services/question.service";
import AlertService from "../services/alert.service";
import ContentMixins from "../mixins/ContentMixins.vue";

export default {
  name: "AddQuestion",
  mixins: [ContentMixins],
  components: {
    QuestionOption,
    QuestionContent,
    QuestionPlaceholder,
    QuestionDragDrop,
    QuestionTypeAhead,
    QuestionCheckbox,
    QuestionDefaultValue
  },
  unmounted() {},
  computed: {
    showDisplayWideLabel: function() {
      if (typeof this.selectedWidgetType != "undefined") {
        return this.selectedWidgetType.name == "Radio Button Group" || this.selectedWidgetType.name == "Checkbox Group" || this.selectedWidgetType.name == "Dropdown" ||
               this.selectedWidgetType.name == "Textbox" || this.selectedWidgetType.name == "Phone Number" || this.selectedWidgetType.name == "Email Address";
      }
      return false;
    },
    displayNumberOfColumns: function() {
      if (typeof this.selectedWidgetType != "undefined") {
        return this.selectedWidgetType.name == "Radio Button Group" || this.selectedWidgetType.name == "Checkbox Group";
      }
      return false;
    },
    displayPlaceholder: function() {
      if (typeof this.selectedWidgetType != "undefined") {
        return this.selectedWidgetType.name == "Textbox" || this.selectedWidgetType.name == "Phone Number" || this.selectedWidgetType.name == "Email Address";
      }
      return false;
    },
    displayDefaultValue: function() {
      if (typeof this.selectedWidgetType != "undefined") {
        return this.selectedWidgetType.name == "Hidden";
      }
      return false;
    }
  },
  mounted() {
    this.setSelectedWidgetType();
    this.setupAddQuestionForm();
    if (this.$parent.displayIncludeAsSegment){
      this.newQuestionRequired = "Y";
      this.newIncludeAsSegment = "Y";
    }
  },
  methods: {
    showConfirmationMessage() {
      AlertService.showConfirmationMessage(this.resetQuestion);      
    },
    disableButtons: function() {
      this.disableSaveButton = true;
      this.disableCancelButton = true;
    },
    enableButtons: function() {
      this.disableSaveButton = false;
      this.disableCancelButton = false;
    },
    reloadContentSections: function() {
      this.$parent.$emit("reload");
    },
    setWidgetConfigurationContentId: function(result) {
      this.widgetConfiguration.contentId = result.contentId;
      this.newQuestionLabel = "Content - " + result.name;
      this.$parent.$emit("reload");
    },
    setWidgetConfigurationDefaultValue:function(result) {
      this.widgetConfiguration.defaultValue = result;
    },
    setWidgetConfigurationPlaceholder: function(result) {
      this.widgetConfiguration.placeholder = result;
    },
    setWidgetConfigurationCheckboxLimitResponsesOnly(enable){
      this.widgetConfiguration.maximumNumberOfValuesEnabled = enable;
    },
    setWidgetConfigurationCheckboxOrderOnly(order){
      this.widgetConfiguration.choiceOrder = order;
    },    
    setWidgetConfigurationCheckbox(enable, maxNumberOfChoices,options,order){
      this.widgetConfiguration.options = options;
      this.widgetConfiguration.choiceOrder = order;
      this.widgetConfiguration.maximumNumberOfValuesEnabled = enable;
      this.widgetConfiguration.maximumNumberOfValues = maxNumberOfChoices;
    },
    setWidgetConfigurationDragDrop:function(choices, order, numberOfChoicesToRank) {
      this.widgetConfiguration.choices = choices;
      this.widgetConfiguration.choiceOrder = order;
      this.widgetConfiguration.numberOfChoicesToRank = numberOfChoicesToRank;
    },
    checkQuestionDisplayOnSurvey: function(e) {
      if (e.target.checked) {
        this.newQuestionDisplay == "Y";
      } else {
        this.newQuestionDisplay == "N";
      }
    },
    checkQuestionRequired: function(e) {
      if (e.target.checked) {
        this.newQuestionRequired == "Y";
      } else {
        this.newQuestionRequired == "N";
      }
    },
    resetQuestion() {
      this.$parent.$data.mode = "view";
      this.newQuestionLabel = "";
      this.newQuestionRequired = "N";
      this.newQuestionDisplay = "Y";
      this.newIncludeAsSegment = "N";
      this.newSegmentLabel = "";
      this.newQuestionWidgetTypeId = "";
      this.widgetConfiguration = {
        options: []
      };
      this.setSelectedWidgetType();
    },
    saveOption: function(result) {
      var option = this.widgetConfiguration.options.find(x => x.value == result.value);
      if (option !== undefined) {
        option.name = result.name;
      }
    },
    removeOption: function(result) {
      var option = this.widgetConfiguration.options.find(x => x.value == result.value);
      if (option !== undefined) {
        this.widgetConfiguration.options.splice($.inArray(option, this.widgetConfiguration.options), 1);
      }
    },
    checkSegmentLimit(event) {
      if (event.target.checked) {
        if (1 + this.$parent.totalNumberOfChartSegments > this.$parent.maximumNumberOfChartSegments) {
          AdminService.displayErrorMessage({text: "The maximum number of chart segments have been selected. If you want to include any additional selections you must deselect an existing question.",duration: -1})
          event.preventDefault();
          this.newIncludeAsSegment = "N";
          event.target.checked = "off";
        } else {
          this.newIncludeAsSegment == "Y";
        }
      } else {
        this.newIncludeAsSegment == "N";
      }
    },
    async addQuestion() {
      if (!this.addQuestionForm.valid()) {
        return false;
      } else {
        if (
          this.newIncludeAsSegment == "Y" &&
          this.selectedWidgetType !== undefined &&
          this.selectedWidgetType.name != "Hidden" &&
          this.selectedWidgetType.name != "Dropdown" &&
          this.selectedWidgetType.name != "Drag and Drop" &&
          this.selectedWidgetType.name != "Radio Button Group" &&
          this.selectedWidgetType.name != "Checkbox Group"
        ) {
          AdminService.displayErrorMessage({ text: "You must have a field type of dropdown, radio button, or checkbox if the question is marked as a segment.", duration: -1 })
          return;
        }
        if (this.newMultiQuestionLabel.length > 0) {
          this.newQuestionLabel = this.newMultiQuestionLabel.toString();
        }
        this.$root.processServerRequest("Adding question");        
        var result = {
          surveyId: this.surveyId,
          sectionId: this.sectionId,
          label: this.newQuestionLabel,
          required: this.newQuestionRequired,
          display: this.newQuestionDisplay,
          widgetTypeId: this.newQuestionWidgetTypeId,
          sequenceNumber: this.newQuestionIndex,
          includeAsSegment: this.newIncludeAsSegment,
          includeInExecutiveDownload: this.newIncludeInExecutiveDownload,
          segmentLabel: this.newSegmentLabel,
          widgetConfiguration: JSON.stringify(this.widgetConfiguration)
        };
        if (!this.initialLoad) {
          QuestionService.addQuestion(result, this.addQuestionCallback, this);
        }
      }
    },
    addQuestionCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.$parent.$data.questions.push(response.data.data);
        this.$parent.$data.mode = "view";
        this.$parent.setTotalNumberOfChartSegments();
        this.resetQuestion() ;
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    setSelectedWidgetType() {
      this.selectedWidgetType = this.getWidgetType(this.newQuestionWidgetTypeId);
      if (!jQuery.isEmptyObject(this.selectedWidgetType)) {
        if (this.selectedWidgetType.name == "Dropdown") {
          this.widgetConfiguration.options = [
            { name: "", value: "-1" },
            { name: "PLEASE SELECT", value: "0", selected: "selected" },
            { name: " ", value: "1" }
          ];
        } else if (this.selectedWidgetType.name == "Checkbox Group" || this.selectedWidgetType.name == "Radio Button Group" || this.selectedWidgetType.name == "Hidden") {
          this.widgetConfiguration.options = [{ name: " ", value: "1" }];
        } else {
          this.widgetConfiguration.options = [];
        }
        if (this.selectedWidgetType.name == "Slider") {
          if (jQuery.isEmptyObject(this.Configuration)) {
            this.widgetConfiguration = { min: 6, max: 605, step: 100, value: 308 };
          }
        }
        if (this.selectedWidgetType.name == "Checkbox Group") {
          if (!Object.prototype.hasOwnProperty.call(this.widgetConfiguration, "maximumNumberOfValuesEnabled")) {    
            this.widgetConfiguration.maximumNumberOfValuesEnabled = "N";
          }
          if (!Object.prototype.hasOwnProperty.call(this.widgetConfiguration, "maximumNumberOfValues")) {    
            this.widgetConfiguration.maximumNumberOfValues = 1;
          }
          if (!Object.prototype.hasOwnProperty.call(this.widgetConfiguration, "choiceOrder")) {    
            this.widgetConfiguration.choiceOrder = "random";
          }
        }
        if (this.selectedWidgetType.name == "Drag and Drop") {
          if (!Object.prototype.hasOwnProperty.call(this.widgetConfiguration, "choices")) {    
            this.widgetConfiguration.choices = [{name: "",sequenceNumber: 1},{name: "",sequenceNumber: 2}];
          }
          if (!Object.prototype.hasOwnProperty.call(this.widgetConfiguration, "choices")) {    
            this.widgetConfiguration.choices = [{name: "",sequenceNumber: 1},{name: "",sequenceNumber: 2}];
          }
        }         
      }
      if (!Object.prototype.hasOwnProperty.call(this.widgetConfiguration, "choiceOrder")) {    
        this.widgetConfiguration.choiceOrder = "random";
      }
    },
    getWidgetType(widgetId) {
      if (this.widgetTypeOptions) {
        return this.widgetTypeOptions.find(x => x.value == widgetId);
      } else {
        return {};
      }
    },
    setupAddQuestionForm() {
      this.addQuestionForm = $("#QuestionFormId_" + this.sectionId);
      jQuery.validator.setDefaults({
        debug: true,
        success: "valid"
      });
      jQuery.validator.addMethod(
        "notEqual",
        function(value, element, param) {
          return this.optional(element) || value != param;
        },
        "Please choose a value!"
      );
      this.validator = this.addQuestionForm.validate({
        rules: {
          widgetType: {
            required: true,
            notEqual: "0"
          }
        },
        highlight: function(element) {
          $(element)
            .closest(".form-group")
            .removeClass("has-success")
            .addClass("has-error");
        },
        unhighlight: function(element) {
          $(element)
            .closest(".form-group")
            .removeClass("has-error")
            .addClass("has-success");
        },
        errorElement: "small",
        errorClass: "form-text text-danger",
        errorPlacement: function(error, element) {
          if (element.length) {
            var targetParent = $(element).parent();
            if (targetParent.hasClass("form-check") || targetParent.hasClass("custom-control")) {
              targetParent = targetParent.parent();
            }
            targetParent.append(error);
          }
        }
      });
    }
  },
  watch: {},
  data() {
    return {
      disableSaveButton: false,
      disableCancelButton: false,
      addQuestionForm: null,
      validator: null,
      newQuestionLabel: "",
      newQuestionRequired: "N",
      newQuestionDisplay: "Y",
      newIncludeAsSegment: "N",
      newIncludeInExecutiveDownload: "N",
      newSegmentLabel: "",
      newQuestionWidgetTypeId: "",
      selectedWidgetType: {},
      newMultiQuestionLabel: [],
      widgetConfiguration: {
        options: []
      },
      number_of_columns_options: QuestionService.getNumberOfColumnOptions(),
    };
  },
  props: {
    newQuestionIndex: {
      type: Number,
      default: 0
    },
    widgetTypeOptions: {
      type: Array,
      default: function() {
        return [];
      }
    },
    surveyId: {
      type: Number,
      default: 0
    },
    sectionId: {
      type: Number,
      default: 0
    },
    displayRequired: {
      type: Boolean,
      default: false
    },
    showDisplayField:{
      type: Boolean,
      default: false
    },
    displayIncludeAsSegment: {
      type: Boolean,
      default: false
    },
    displayIncludeInExecutiveDownload: {
      type: Boolean,
      default: false
    },
  }
};
</script>
