<template>
    <div class="e-chart-buttons d-flex justify-content-end">
      <div class="d-flex align-items-center">
        <div v-show="displayAngleText" class="form-group form-inline mb-0">
            <input
            type="checkbox"
            class="form-check-input"
            id="angleText"
            name="angleText"
            v-model="angleText"
            @click="changeAngleText($event)"
            />
            <label for="angleText">Angle X-axis Text </label>
        </div>                   
        <div v-show="displayShowRespondents" class="form-group form-inline mb-0">
            <input type="checkbox" 
              id="displayNumberOfResponses" 
              name="displayNumberOfResponses" 
              v-model="displayNumberOfResponses" 
              @click="changeDisplayNumberOfResponses($event)"/>
            <label for="segment" class="control-label pl-1">Show Respondents (n=)</label>
        </div>
        <div  v-show="displaySortBy" class="pl-2">
          <select id="sortBy" name="sortBy" class="custom-select required" v-model="sortBy" @change="setSortBy()">
            <option value>Sort By</option>
            <option v-for="(sort_by_option, idxSortByOptions) in this.sort_by_options" :value="sort_by_option.value" :key="idxSortByOptions">
              {{ sort_by_option.name }}
            </option>
          </select>
        </div>
      </div>
      <div v-for="(pageContent, idxPageContent) in pageContentForNPS" v-bind:key="idxPageContent">
        <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
      </div>      
    </div>
</template>

<script>
import EChartsButtonsMixins from '../../mixins/EChartsButtonsMixins.vue';
import Modal from "../Modal.vue";
import ContentService from '../../services/content.service';
export default {
  name: "ProfileChartButtons",
  mixins: [EChartsButtonsMixins],
  components: {Modal},
  data() {
    return {
      showGoalLine: true,
      angleText: true,
      displayNumberOfResponses: false,
      pageContentForNPS: [],
      sort_by_options: [
        { name: "Willingness to Pay", value: "willingnessToPay" },
        { name: "Value Gap", value: "valueGap" },
      ],
      sortBy: "valueGap",
    };
  },
  props: {
    inputShowGoalLine: {
        type: Boolean,
        default: true
    },       
    inputSortBy: {
        type: String,
        default: ""
    },
    inputAngleText: {
        type: Boolean,
        default: true
    },   
    inputDisplayNumberOfResponses: {
        type: Boolean,
        default: true
    },   
    displayShowRespondents: {
        type: Boolean,
        default: true
    },
    displaySortBy: {
        type: Boolean,
        default: false
    }
  },
  computed: {
    displayAngleText: function(){
        //return this.eChartType == "stacked-bar-vertical";
        return false;
    },
  },
  mounted() {
    this.setChart(this.inputEChartType, this.getChartIndex(this.inputEChartType,this.inputRecalculate), this.inputRecalculate);      
    this.displayNumberOfResponses = this.inputDisplayNumberOfResponses;
  },
  created() {
    ContentService.getPageContent("NPS", this.getPageContentByNPSCallBack, this);    
  },
  methods: {
    getPageContentByNPSCallBack(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.pageContentForNPS = response.data.data;
      }
    },      
    changeDisplayNumberOfResponses: function(event) {
        this.displayNumberOfResponses = event.target.checked;
        this.$emit("set-display-number-of-responses", this.displayNumberOfResponses);
    },    
    setSortBy: function(){
        this.$emit("set-sort-by", this.sortBy);
    },
    changeAngleText: function(event) {
        this.angleText = event.target.checked;
        this.$emit("set-angle-text", this.angleText);
    },
    getClass:function(index){
        return this.chartSelected == index ? "selected" : "";
    },
    verifyChartCriteria: function(eChartType){
        let verified = true;
        return verified;
    },
  },
  watch: {
    inputSortBy: function(){
      if (this.inputSortBy != ""){
        this.sortBy = this.inputSortBy;
      }
    },
    inputAngleText: function(){
        this.angleText = this.inputAngleText;
    },
    inputShowGoalLine: function(){
        this.showGoalLine = this.inputShowGoalLine;
    },
    inputDisplayNumberOfResponses: function(){
        this.displayNumberOfResponses = this.inputDisplayNumberOfResponses;
    },
  },
};
</script>
