<template>
  <div class="form-inline w-100">
    <div class="col-2 font-weight-bold">
      {{ groupName }}
    </div>
    <div class="col-1">
      {{ metricTypeName }}
    </div>
    <div class="col-1">
      {{ metricDisplayTypeName }}
    </div>
    <div class="col-1">
      <select id="year" name="year" class="custom-select required w-100" :value="metricObject.year" @input="updateMetricProperty($event.target.value,'year')">
        <option value>Year</option>
        <option v-for="(year_option, idxYearOptions) in this.$parent.$data.year_options" v-bind:value="year_option.value" v-bind:key="idxYearOptions">
          {{ year_option.name }}
        </option>
      </select>
    </div>
    <div class="col-2">
      <select id="period" name="period" class="custom-select required" :value="metricObject.period" @input="updateMetricProperty($event.target.value,'period')">
        <option value>Period</option>
        <option v-for="(period_option, idxPeriodOptions) in this.periodList" v-bind:value="period_option.value" v-bind:key="idxPeriodOptions">
          {{ period_option.name }}
        </option>
      </select>
    </div>
    <div v-show="displayCurrencyDropdown" class="col-2">
      <currency-input
        v-show="displayCurrencyDropdown"
        class-object="form-control w-75 mx-auto"
        :value="parseInt(metricObject.metric)"
        :currency="metricCurrency"
        @input="updateMetric"
        :currency-options="this.$parent.currency_options"
      ></currency-input>
    </div>
    <div v-show="!displayCurrencyDropdown" class="col-2">
      <input type="text" class="form-control w-75 mx-auto" name="metric" :value="metricObject.metric" @change="updateMetric($event.target.value)" />
    </div>

    <div class="col-1 my-auto">
      <div v-if="metricObject.metricGrowth == 0" class="text-center">--</div>
      <div v-else v-bind:class="[metricObject.metricGrowth > 0 ? positiveClass : negativeClass]">{{ $parent.useValueToCalculateGrowth ? metricObject.metricGrowth : Number(metricObject.metricGrowth).toFixed(1) + '%' }} </div>
    </div>
    <div class="col-1 d-flex">
      <div class="w-30">
        <a v-if="displayAddWave()" href="#!" @click="this.addMetric" class="btn-lg">
          <font-awesome-icon :icon="['fa', 'plus-circle']" />
        </a>
      </div>
      <div class="w-30">
        <a v-if="displayRemoveWave()" href="#!" @click="this.removeMetric" class="btn-lg">
          <font-awesome-icon :icon="['fa', 'minus-circle']" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import CurrencyInput from "./CurrencyInput.vue";
import ClientTimePeriodService from "../services/client.time.period.service";

export default {
  name: "MetricEntry",
  components: {
    CurrencyInput,
  },
  props: {
    metricObject: {
      type: Object,
      default: function () {
        return {};
      },
    },
    newMetricIndex: {
      type: Number,
      default: 0,
    },
    groupName: {
      type: String,
      default: "",
    },
    metricTypeName: {
      type: String,
      default: "",
    },    
    metricDisplayTypeName: {
      type: String,
      default: "",
    },
    metricCurrency: {
      type: String,
      default: "US Dollars",
    },
  },
  data() {
    return {
      positiveClass: "text-success",
      negativeClass: "text-danger",
      localMetricEntry: this.metricObject,
    };
  },
  methods: {
    updateMetricProperty(value,key){
      this.localMetricEntry = JSON.parse(JSON.stringify(this.metricObject));
      if (key == "period"){
        this.localMetricEntry.period = parseInt(value);
      } else if (key == "year"){
        this.localMetricEntry.year = parseInt(value);
      } 
      this.$emit("updateRowData", this.newMetricIndex, this.localMetricEntry);
    },
    updateMetric(value) {
      this.localMetricEntry = JSON.parse(JSON.stringify(this.metricObject));
      this.localMetricEntry.metric = value;
      this.$emit("updateRowData", this.newMetricIndex, this.localMetricEntry);
    },
    addMetric: function () {
      this.$emit("update", this.newMetricIndex, this.localMetricEntry);
      this.$emit("save");
    },
    removeMetric: function () {
      this.$emit("remove", this.metricObject.metricId);
    },
    displayAddWave: function () {
      let currentWaveSize = this.$parent.metricsList.length;
      if (this.newMetricIndex == currentWaveSize - 1) {
        return true;
      } else {
        return false;
      }
    },
    displayRemoveWave: function () {
      return this.newMetricIndex >= 0;
    },
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {
    displayCurrencyDropdown: function () {
      return this.metricDisplayTypeName == "Currency";
    },
    period_options: function () {
      return ClientTimePeriodService.getPeriodOptions();
    },
    periodList() {
      let originalPeriodOptions = this.period_options.slice(0, this.$parent.clientSettings.numberOfPeriods);
      var match = originalPeriodOptions.find((x) => x.value == this.metricObject.period);
      if (typeof match == "undefined" && typeof this.metricObject.period != "undefined") {
        originalPeriodOptions.push({ name: this.metricObject.period.toString(), value: this.metricObject.period });
      }
      return originalPeriodOptions;
    },
  },
};
</script>
