<template>
  <div class="form-group m-0">
    <input type="text" :class="classObject" id="displayValue" name="displayValue" v-model.lazy="displayValue" @change="updateValue"/>
  </div>
</template>

<script>
import $ from "jquery";
import jQuery from "jquery";

export default {
  name: "CurrencyInput",
  methods: {
    updateValue: function(event) {
      let inputValue = event.target.value;
      let newValue = inputValue.replace(/[^0-9.-]+/g, ''); //seperating currency symbol and value
      let parsedValue = parseFloat(newValue);
      if(isNaN(parsedValue)) {
        parsedValue = 0;
      }
      this.$emit("input", parsedValue);
    }
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
    classObject: {
      type: String,
      default: ""
    },
    currencyOptions: {
      type: Array,
      default: function() {
        return [];
      }
    },
    currency: {
      type: String,
      default: "Us Dollars"
    }
  },
  data() {
    return {};
  },
  computed: {
    displayValue: {
      get: function() {
          let currencyMatch = this.currencyOptions.find(x => x.name == this.currency);
          if (currencyMatch !== undefined && currencyMatch.localString != null && currencyMatch.localString != currencyMatch.currency) {
            return new Intl.NumberFormat(currencyMatch.localString, { style: "currency", currency: currencyMatch.currency }).format(this.value);
          } else {
            return this.value;
          }
      },
      set: function(modifiedValue) {
        let newValue = modifiedValue;
        if (isNaN(newValue)) {
          newValue = this.value;
        }
      }
    }
  }
};
</script>
