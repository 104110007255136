<template>
  <div>
    <div id="chart-container" class="moats">
      <div v-show="!generatingChart" id="moat-rank-title-and-chart" class="title-and-chart">
        <profile-chart-header
            :title="'Moneyball Rank'"
            :display="!generatingChart"
            :input-display-number-of-responses="eChartCriteria.displayNumberOfResponses"
            :input-sort-by="eChartCriteria.sortBy"
            :display-sort-by="true"
            @set-sort-by="setSortBy"
            @set-display-number-of-responses="setDisplayNumberOfResponses"
          />
          <div :style="containerHeightStyle">
          <moneyball-stick-chart ref="eChart"
            :e-chart-mini-id="'moneyball-stick-mini'"
            :e-chart-id="'moneyball-stick'"
            :input-class-name="'e-chart'"
            :input-report-type-name="'Chart - Moneyball Stick Rank'"
            :modal-id="'profile-by-moneyball-stick-chart'"    
            :display-order="displayOrder"
            :order-by-value="orderByValue"
            :is-saved-card="$parent.clientChart.clientChartId != 0" 
            :is-rank="true"
            :input-client-chart="this.$parent.clientChart"
            :inputDownloadFileName="'Moneyball Stick'"
            :container-height="containerHeightStyle"
            @save-table="saveProfileDataTable"
            @save-criteria="buildClientChart"/>                      
          </div>
<!--
            <moneyball-stick-chart ref="eChart1"
            :e-chart-mini-id="'moneyball-stick-mini-1'"
            :e-chart-id="'moneyball-stick-1'"
            :input-class-name="'e-chart'"
            :input-report-type-name="'Chart - Moneyball Stick Rank'"
            :modal-id="'profile-by-moneyball-stick-chart'"    
            :display-order="displayOrder"
            :order-by-value="orderByValue"
            :is-saved-card="$parent.clientChart.clientChartId != 0" 
            :input-client-chart="this.$parent.clientChart"
            :inputDownloadFileName="'Moneyball Stick'"
            @save-table="saveProfileDataTable"
            @save-criteria="buildClientChart"/>                      
  -->          
      </div>
    </div>
    <insights
      ref="insightsChart"
      :modal-name="'moat-rank'"
      :report-page-type-name="'Chart - Moneyball Stick Rank'"
      :entry-page-content="this.pageContentForReport"
      @saveReportPage="$parent.updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="$parent.saveClientChart"
    />
  </div>
</template>
<script>
import ChartCompanyMixins from "../../../mixins/ChartCompanyMixins.vue";

import ProfileChartHeader from "../../../components/echarts/ProfileChartHeader.vue";

import MoneyballStickChart from "../../../views/echarts/MoneyballStickChart.vue";

import ReportService from "../../../services/report.service";
import ErrorService from "../../../services/error.service";

export default {
  name: "MoneyballStickRank",
  mixins: [ChartCompanyMixins],
  components: {MoneyballStickChart,ProfileChartHeader},
  data() {
    return {
      title: "Moneyball Rank",
      isMobile: false,
      orderByValue: "valueGap",   //"valueGap" or "willingnessToPay"
      displayOrder: "asc",       // "asc" or "desc"
      containterHeight: 663,
    };
  },
  props: {
    inputData: {
      type: Object,
      default: function() {
        return {};
      }
    },
  },
  methods: {
    saveAllReportPages() {
      this.saveReportPage("moat-rank","Chart - Moneyball Stick Rank",this.clientChart);
    },     
    async saveProfileDataTable(containerId) {},          
    setDisplayNumberOfResponses(inputDisplayNumberOfResponses){
      this.eChartCriteria.displayNumberOfResponses = inputDisplayNumberOfResponses;
      this.$parent.$refs.chartCriteria.eChartCriteria.displayNumberOfResponses = inputDisplayNumberOfResponses;      
      this.processChartData();
    },    
    setSortBy(inputSortBy){
      if (inputSortBy != ""){
        this.orderByValue = inputSortBy;
        this.$parent.$refs.chartCriteria.eChartCriteria.sortBy = inputSortBy;
        this.processChartData();
      }
    },
    async processChartData() {
      try {
        if (this.orderByValue == "willingnessToPay") {
          this.displayOrder = "desc";
        } else {
          this.displayOrder = "asc";
        }
        let moneyballStickResults = JSON.parse(JSON.stringify(this.inputData));
        if (typeof this.eChartCriteria.displayNumberOfResponses != "undefined" && 
            this.eChartCriteria.displayNumberOfResponses != null &&
            this.eChartCriteria.displayNumberOfResponses == true) { 
              let moneyballStickDataPoints = moneyballStickResults.moneyballStickDataPoints;
              for (let i = 0; i < moneyballStickDataPoints.length; i++) {
                moneyballStickResults.moneyballStickDataPoints[i].name = moneyballStickDataPoints[i].nameWithRespondents;
              }
        } 
        if (this.displayOrder != "" && this.orderByValue != "") {
          moneyballStickResults.moneyballStickDataPoints.sort(ReportService.compareValues( this.orderByValue, this.displayOrder));
        } 
        let numberOfGrids = Math.ceil(moneyballStickResults.moneyballStickDataPoints.length / this.$refs.eChart.$data.maxEntriesPerChart);
       // console.log("numberOfGrids: " + numberOfGrids);
        if (numberOfGrids > 1) {
          this.containterHeight = 663 * numberOfGrids;
        } 
        
        let vm = this;
        setTimeout(() => {
          vm.$refs.eChart.processChart(true,moneyballStickResults,false);
        }, 1500);
        

      } catch (err) {
        ErrorService.processException("MoneyballStickRank.displayChartCallback", err, "chart", this);
      }
    },
  },
  umnounted() {},
  computed: {
  containerHeightStyle() {
      return "height: " + this.containterHeight + "px;";
    },
  },
  mounted() {},
  created() {},
  watch: {
    inputData: function() {
      if (typeof this.$parent.$refs.chartCriteria.eChartCriteria != "undefined" && typeof this.$parent.$refs.chartCriteria.eChartCriteria.sortBy != "undefined") {
        this.orderByValue = this.$parent.$refs.chartCriteria.eChartCriteria.sortBy;
        this.eChartCriteria.sortBy = this.$parent.$refs.chartCriteria.eChartCriteria.sortBy;
      }
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      try{this.$refs.eChart.clear();} catch(e){}
      if (this.$parent.$refs.chartCriteria.$data.displayType == "moat-rank") {
        this.processChartData();
      } else {
        this.setDisplayView(false);
      }
    }
  }
};
</script>
