<template>
  <vue-modal :key="refreshModal" :ref="modalId" :name="modalId" :clickToClose="false" :draggable="true" :adaptive="true" @opened="loadData" :resizable="true" :scrollable="true" :width="modalWidth" :maxHeight="1550" :height="'auto'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="close" @click="closeModal">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="dashboard-modal-card modal-body mx-auto d-flex">
        <dashboard-performance-trends  v-if="saveClientChartObject.displayType == 'performance-trends'" 
          ref="performanceTrends" dashboard-id="performanceTrends" :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
          :entry-client-chart="saveClientChartObject" :read-only="true"
          :input-metric-options="metric_options"
          :entry-survey-code="entrySurveyCode" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'performance-trends',false)"
          :input-survey-group-id="getCriteriaParameterForDashboardCard(saveClientChartObject,'dashboardSurveyGroupId')"
          :input-use-equal-weight="getCriteriaParameterForDashboardCard(saveClientChartObject,'dashboardUseEqualWeight')"
        />
      </div> 
      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-4" @click="closeModal">Close</button>
      </div>
    </div>
  </vue-modal>
</template>
<script>
import DashboardPerformanceTrends from "../components/dashboard/DashboardPerformanceTrends.vue";
import DashboardCardMixins from "../mixins/DashboardCardMixins"
import MetricsService from "../services/metrics.service";
import AdminService from "../services/admin.service";
import SurveyService from "../services/survey.service";

export default {
  name: "DashboardPerformanceTrendsModal",
  mixins: [DashboardCardMixins],
  components: {DashboardPerformanceTrends},
  props: {},
  data() {
    return {
      title: "Respondent Map",
      entrySurveyCode: "",
    };
  },
  methods: {
    loadData: async function(){
      if (this.triggerLoadData == true){
        await this.setupDashboardCard();
        this.entrySurveyCode = SurveyService.getSurveyCodeBySurveyId(this.saveClientChartObject.surveyId);
        await MetricsService.getMetricGroupBySurveyCode(this.entrySurveyCode, this.getMetricGroupByClientIdCallback, this);
      }
    },
    getMetricGroupByClientIdCallback(response) {
      this.metric_options = [];
      this.metricId = "";
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        MetricsService.setMetricDropdownOptions(response.data.data,this);
      } else if (statusCode == 201) {
        //        AdminService.displaySuccessMessage({ text: response.data.message, duration: -1 });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    }    
  },
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
  computed: {
    triggerLoadData: function() {
      return this.saveClientChartObject != null && this.saveClientChartObject.displayType == "performance-trends";
    }
  }
};
</script>
